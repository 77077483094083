import { message } from 'antd';
import dayjs from 'dayjs';
import * as get from 'lodash/get';
import * as max from 'lodash/max';
import * as set from 'lodash/set';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import { mathRound } from '../utils/Format';
import Request from '../utils/Request';

export const GiftlyGSProductStore = types
    .model('GiftlyGSProductStore', {
        isLoading: false,
        isBrandShippingLoading: false,
        needToReFetch: false,
        selectedProduct: types.optional(
            types.maybeNull(
                types.model({
                    _id: types.maybeNull(types.string),
                    account: types.frozen(),
                    promotion: types.model({
                        _id: types.maybeNull(types.string),
                        title: types.string,
                        images: types.frozen(),
                        googleTaxonomy: types.optional(types.maybeNull(types.string), null),
                        variants: types.array(
                            types.model({
                                externalId: types.string,
                                enabled: types.boolean,
                                klicklyProfit: types.number,
                                paidPercent: types.number,
                                title: types.string,
                                price: types.number,
                                brandShipping: types.maybeNull(types.number),
                                shippingEditable: types.boolean,
                                manualShipping: types.maybeNull(types.number),
                                customPrice: types.maybeNull(types.number),
                                klicklyCCCProfit: types.number,
                                klicklyNCCProfit: types.number
                            })
                        )
                    }),
                    promotionStatus: types.string,
                    useOn: types.optional(
                        types.model({
                            onboarding: types.optional(types.maybeNull(types.boolean), null),
                            ongoing: types.optional(types.maybeNull(types.boolean), null),
                            partnerListing: types.optional(types.maybeNull(types.boolean), null)
                        }),
                        {
                            onboarding: false,
                            ongoing: false,
                            partnerListing: false
                        }
                    ),
                    klicklyPrice: types.maybeNull(types.number),
                    klicklyShippingPrice: types.maybeNull(types.number),
                    klicklyPriceAutoRecalculate: types.optional(types.boolean, false),
                    klicklyPriceRecalculatedAt: types.maybeNull(types.string),
                    quantity: types.optional(types.number, 1),
                    qualityScore: types.optional(types.number, 1),
                    coverShipping: types.optional(types.boolean, false),
                    coverTax: types.optional(types.boolean, false),
                    active: types.optional(types.boolean, true),
                    priority: types.optional(types.number, 0)
                })
            ),
            null
        ),
        goToExistsCSP: types.optional(types.maybeNull(types.string), null)
    })
    .views((self) => ({
        get isGiftlyRulesEnabled() {
            return (
                self.selectedProduct &&
                self.selectedProduct.useOn &&
                (self.selectedProduct.useOn.onboarding || self.selectedProduct.useOn.ongoing)
            );
        },
        get productVariants() {
            return self.getProductField('promotion.variants', []);
        },
        get productCategory() {
            return self.getProductField('promotion.category', null);
        },
        get klicklyPriceRecalculatedAt() {
            const date = self.getProductField('klicklyPriceRecalculatedAt', null);
            if (date) {
                return dayjs(date).format('MMM DD, YYYY; hh:mma');
            }

            return 'never';
        }
    }))
    .actions((self) => {
        const changeNeedToReFetch = (value) => {
            self.needToReFetch = value;
        };

        const clear = () => {
            self.selectedProduct = null;
            self.goToExistsCSP = null;
        };

        const setProductField = (field, value, recalculateProfit = false) => {
            set(self.selectedProduct, field, value);
            if (recalculateProfit) {
                self.productVariants.forEach((variant) => self.reCalculateVariantProfit(variant));
            }
        };

        const getProductField = (value, defaultValue = '') => {
            return get(self.selectedProduct, value, defaultValue);
        };

        const changeVariantValue = (index, field, value, recalculateProfit = false) => {
            set(self.selectedProduct, `promotion.variants.${index}.${field}`, value);
            if (recalculateProfit) {
                self.reCalculateVariantProfit(self.getProductField(`promotion.variants.${index}`));
            }
        };

        const customerPayment = (variantBrandShipping) => {
            let payment = self.getProductField('klicklyPrice', 0);
            if (!self.getProductField('coverShipping', false)) {
                payment += variantBrandShipping;
            } else {
                payment += self.getProductField('klicklyShippingPrice', 0);
            }
            return payment;
        };

        const reCalculateVariantProfit = (variant) => {
            const variantShipping = variant.brandShipping || variant.manualShipping || 0;
            const variantQtyPrice = variant.price * self.getProductField('quantity', 1);
            const customerPayment = self.customerPayment(variantShipping);
            const taxCoverPrice = self.getProductField('coverTax', false) ? variantQtyPrice * 0.1 : 0;
            const customerPayTax = !self.getProductField('coverTax', false) ? variantQtyPrice * 0.1 : 0;
            const paidForBrand = mathRound(variantQtyPrice + variantShipping + taxCoverPrice);

            const commissions = {
                ncc: self.getProductField('account.commissions.newCustomerCommission', 0),
                ccc: self.getProductField('account.commissions.coldCustomerCommission', 0)
            };
            const limit = self.getProductField('account.commissions.limitNewColdCustomerCommission', false);

            if (limit) {
                if (commissions.ncc > variantQtyPrice) {
                    commissions.ncc = variantQtyPrice;
                }

                if (commissions.ccc > variantQtyPrice) {
                    commissions.ccc = variantQtyPrice;
                }
            }

            variant.klicklyNCCProfit = mathRound(commissions.ncc - paidForBrand + customerPayment);
            variant.klicklyCCCProfit = mathRound(commissions.ccc - paidForBrand + customerPayment);
            variant.klicklyProfit = max([variant.klicklyNCCProfit, variant.klicklyCCCProfit]);
            variant.paidPercent = mathRound(((customerPayment + customerPayTax) / (paidForBrand || 1)) * 100);
        };

        const initGSProduct = flow(function* (selectedGSProduct) {
            if (selectedGSProduct && selectedGSProduct !== 'new') {
                self.isLoading = true;
                self.selectedProduct = yield Request.get(adminAPI.kcp.csp.get(selectedGSProduct));
                self.isLoading = false;
            }
        });

        const save = flow(function* ({ pricesForm, variantsForm, onClose }) {
            try {
                self.isLoading = true;

                if (self.isGiftlyRulesEnabled) {
                    yield Promise.all([pricesForm.validateFields(), variantsForm.validateFields()]);
                }

                if (self.selectedProduct._id) {
                    yield Request.put(adminAPI.kcp.csp.update(self.selectedProduct._id), self.selectedProduct);
                } else {
                    yield Request.post(adminAPI.kcp.csp.create, self.selectedProduct);
                }

                self.needToReFetch = true;
                self.isLoading = false;

                if (typeof onClose === 'function') {
                    self.clear();
                    onClose(self.needToReFetch);
                }

                message.success('Gifting suite product saved successfully');
            } catch (error) {
                self.isLoading = false;

                if (!error.errorFields) {
                    message.error(error.message || 'Please try again');
                } else {
                    message.error('Validation failed, please check all required fields');
                }
            }
        });

        const searchNewProduct = flow(function* (value) {
            try {
                const clearValue = value.replace(/ObjectId|"|'|\(|\)/gi, '');
                const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(clearValue);

                if (!isValidObjectId) {
                    message.warn('Please enter valid promotion id');
                    return;
                }

                self.isLoading = true;
                self.clear();

                const result = yield Request.get(adminAPI.kcp.csp.addRequest(clearValue));

                if (result.errorStatus && result.errorStatus === 'exists') {
                    self.goToExistsCSP = result.cspId;
                } else {
                    self.selectedProduct = result;
                }
            } catch (error) {
                message.error(error.message || 'Please try again');
            } finally {
                self.isLoading = false;
            }
        });

        const updateBrandShipping = flow(function* () {
            try {
                self.isBrandShippingLoading = true;

                const data = yield Request.post(
                    adminAPI.kcp.csp.updateBrandShipping(self.selectedProduct._id),
                    self.selectedProduct
                );

                if (data.ok) {
                    self.selectedProduct = yield Request.get(adminAPI.kcp.csp.get(self.selectedProduct._id));
                }
            } catch (error) {
                message.error(error.message || 'Please try again');
            } finally {
                self.isBrandShippingLoading = false;
            }
        });

        return {
            updateBrandShipping,
            changeNeedToReFetch,
            clear,
            setProductField,
            getProductField,
            changeVariantValue,
            customerPayment,
            reCalculateVariantProfit,

            initGSProduct,
            save,
            searchNewProduct
        };
    });

import React, { useState } from 'react';
import { Button, Divider, Input, Typography } from 'antd';
import { inject } from 'mobx-react';
import { AuthLayout } from '../../components/Layout';

export const PageMFAVerify = inject('store')(({ store }) => {
    const [mfaToken, setMfaToken] = useState('');
    const onMFATokenChange = (e) => {
        setMfaToken(e.target.value);
    };

    return (
        <AuthLayout>
            <Typography.Title level={4}>Two-Factor Authentication (2FA)</Typography.Title>
            <Divider />
            <div>Open the two-step verification app on your mobile device to get your verification code.</div>
            <Divider />
            <Input onChange={onMFATokenChange} placeholder='Enter code (XXXXXX)' />
            <Divider />
            <Button
                onClick={() => store.user.verifyMFAData(mfaToken)}
                size='large'
                type='primary'
                htmlType='submit'
                className='login-form-button'
            >
                Authenticate
            </Button>
        </AuthLayout>
    );
});

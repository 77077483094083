import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthLayout } from '../components/Layout';
import { SignInForm } from './components/SignInForm';
import { SignInGoogle } from './components/SignInGoogle';

export const PageSignIn = () => {
    return (
        <AuthLayout>
            <Switch>
                <Redirect exact from='/sign-in' to='/sign-in/google' />
                <Route exact path='/sign-in/google' component={SignInGoogle} />
                <Route exact path='/sign-in/manual' component={SignInForm} />
            </Switch>
        </AuthLayout>
    );
};

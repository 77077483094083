import { message } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const OrderInsightsStore = types
    .model('OrderInsightsStore', {
        isLoading: false,
        isExtendLoading: false,
        data: types.maybeNull(types.frozen()),
        displayModal: false,
        analyticsHistory: types.maybeNull(types.frozen()),
        analyticsHistoryPage: 1
    })
    .views((self) => ({
        get drawerTitle() {
            if (self.data) {
                return 'Insights';
            }

            return 'Loading...';
        },
        get sessionHistory() {
            return self.data?.events.session || [];
        }
    }))
    .actions((self) => {
        const toggleModal = (visible) => {
            self.displayModal = visible;
        };

        const init = flow(function* init(orderId) {
            try {
                self.isLoading = true;
                self.analyticsHistoryPage = 1;
                const data = yield Request.get(adminAPI.orders.insights(orderId));
                self.data = data;
                self.analyticsHistory = data?.events.analytics || [];
                self.isLoading = false;
            } catch (error) {
                message.error(error.message);
            }
        });

        const onExtendAnalytics = flow(function* onExtendAnalytics(orderId) {
            try {
                self.isExtendLoading = true;
                const data = yield Request.get(adminAPI.orders.extend(orderId), { page: self.analyticsHistoryPage });
                self.analyticsHistoryPage = data?.events?.page;
                self.analyticsHistory = [...self.analyticsHistory, ...(data?.events?.analytics || [])];
                self.isExtendLoading = false;
            } catch (error) {
                message.error(error.message);
            }
        });

        const handleOnClose = () => {
            self.data = null;
        };

        return {
            onExtendAnalytics,
            handleOnClose,
            toggleModal,
            init
        };
    });

import React from 'react';
import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { inject } from 'mobx-react';

export const SignInForm = inject('store')(({ store }) => {
    const onFinish = (values) => {
        store.user.signIn(values);
    };

    return (
        <Form name='basic' layout='vertical' onFinish={(values) => onFinish(values)}>
            <Form.Item
                name='email'
                rules={[
                    { type: 'email', message: 'Please enter correct email address' },
                    { required: true, message: 'Please enter your email' }
                ]}
            >
                <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Email' />
            </Form.Item>
            <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password' }]}>
                <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder='Password'
                />
            </Form.Item>

            <Form.Item>
                <Button
                    size='large'
                    icon={<LoginOutlined />}
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                >
                    Sign in
                </Button>
            </Form.Item>
        </Form>
    );
});

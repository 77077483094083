import { message } from 'antd';
import { flow, getParent, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { meta } from './common';

export const BlockedEmailsStore = types
    .model('BlockedEmailsStore', {
        loaders: types.optional(
            types.model({
                emails: types.boolean,
                domains: types.boolean,
                addForm: types.boolean
            }),
            {
                emails: false,
                domains: false,
                addForm: false
            }
        ),
        currentForm: 'upload',
        object: types.optional(
            types.model({
                emails: types.model({
                    meta,
                    list: types.optional(types.array(types.frozen()), []),
                    page: 1
                }),
                domains: types.model({
                    meta,
                    list: types.optional(types.array(types.frozen()), []),
                    page: 1
                })
            }),
            { emails: {}, domains: {} }
        ),
        addFormVisible: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get actionUrl() {
            return adminAPI.account.blockedEmails.actionUrl(self.root.account.getValue('_id'));
        }
    }))
    .actions((self) => {
        const changeForm = (form) => {
            self.currentForm = form;
        };

        const toggleAddForm = (status) => {
            self.addFormVisible = status;
        };

        const handleTableChange = (instance, page) => {
            self.object[instance].page = page;
            self.fetch(instance);
        };

        const fetch = flow(function* fetch(instance) {
            self.loaders[instance] = true;
            try {
                const { meta, data } = yield Request.get(self.actionUrl, {
                    page: self.object[instance].page,
                    search: instance
                });
                self.object[instance].meta = meta;
                self.object[instance].list = data;
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders[instance] = false;
            }
        });

        const removeAll = flow(function* removeAll() {
            self.loaders.domains = true;
            self.loaders.emails = true;

            try {
                yield Request.delete(`${self.actionUrl}?all=${true}`);
                yield self.fetch('domains');
                yield self.fetch('emails');
            } catch (error) {
                message.error(error.message);
            }
        });

        const removeItem = flow(function* removeItem(instance, id) {
            self.loaders[instance] = true;
            try {
                yield Request.delete(`${self.actionUrl}/${id}`);
                yield self.fetch(instance);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders[instance] = false;
            }
        });

        const submitCreate = flow(function* submitCreate(data, onClose) {
            self.loaders.addForm = true;
            try {
                yield Request.post(`${self.actionUrl}`, data);
                onClose(true);
                self.currentForm = 'upload';
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders.addForm = false;
            }
        });

        const fileUploadOnChange = (info, onClose) => {
            const { status } = info.file;
            if (status === 'uploading') {
                self.loaders.addForm = true;
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                onClose(true);
                self.loaders.addForm = false;
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                self.loaders.addForm = false;
            }
        };

        return {
            changeForm,
            toggleAddForm,
            handleTableChange,
            fetch,
            removeAll,
            removeItem,
            submitCreate,
            fileUploadOnChange
        };
    });

import React from 'react';
import * as Icons from '@ant-design/icons';

const defaultSearchParams = (limit = true, page = true, sort = true, order = true) => {
    return {
        ...{},
        ...(limit ? { limit: '10' } : {}),
        ...(page ? { page: '1' } : {}),
        ...(sort ? { sort: 'created_at' } : {}),
        ...(order ? { order: 'desc' } : {})
    };
};

export const brands = (match) => [
    {
        icon: <Icons.SecurityScanOutlined />,
        text: 'Products',
        key: 'products-review',
        menuTab: 'products-review',
        link: `${match.path}/review`,
        sublinks: [
            {
                link: `${match.path}/review/promotions/in-review`,
                icon: <Icons.ExclamationCircleOutlined />,
                text: 'New',
                key: 'review-new',
                menuTab: 'review-new'
            },
            {
                link: `${match.path}/review/promotions/accepted`,
                icon: <Icons.CheckCircleOutlined />,
                text: 'Accepted',
                key: 'review-accepted',
                menuTab: 'review-accepted'
            },
            {
                link: `${match.path}/review/promotions/rejected`,
                icon: <Icons.StopOutlined />,
                text: 'Rejected',
                key: 'review-rejected',
                menuTab: 'review-rejected'
            }
        ]
    },
    {
        link: `${match.path}/creative-settings`,
        icon: <Icons.BgColorsOutlined />,
        text: 'Creative settings',
        key: 'creative-settings',
        menuTab: 'creative-settings'
    },
    {
        link: `${match.path}/accounts`,
        icon: <Icons.ReconciliationOutlined />,
        text: 'Accounts',
        key: 'accounts',
        menuTab: 'accounts'
    },
    {
        icon: <Icons.ShopOutlined />,
        text: 'Orders',
        key: 'orders',
        menuTab: 'orders',
        link: `${match.path}/orders`,
        sublinks: [
            {
                link: `${match.path}/orders/history-list`,
                icon: <Icons.UnorderedListOutlined />,
                text: 'Orders history',
                key: 'orders-history-list',
                menuTab: 'orders-history-list'
            },
            {
                link: `${match.path}/orders/tracking-number`,
                icon: <Icons.FileSearchOutlined />,
                text: 'Tracking number',
                key: 'orders-tracking-number',
                menuTab: 'orders-tracking-number'
            }
        ]
    },
    {
        link: `${match.path}/privacy`,
        icon: <Icons.LockOutlined />,
        text: 'Customer privacy',
        key: 'privacy',
        menuTab: 'privacy'
    }
];

export const brandsV2 = (match) => [
    {
        icon: <Icons.SecurityScanOutlined />,
        text: 'Products',
        key: 'products-v2',
        menuTab: 'products',
        link: `${match.path}/review/products/in-review`,
        sublinks: [
            {
                link: `${match.path}/review/products/in-review`,
                icon: <Icons.ExclamationCircleOutlined />,
                text: 'New',
                key: 'review-new-v2',
                menuTab: 'review-new'
            },
            {
                link: `${match.path}/review/products/accepted`,
                icon: <Icons.CheckCircleOutlined />,
                text: 'Accepted',
                key: 'review-accepted-v2',
                menuTab: 'review-accepted'
            },
            {
                link: `${match.path}/review/products/rejected`,
                icon: <Icons.StopOutlined />,
                text: 'Rejected',
                key: 'review-rejected-v2',
                menuTab: 'review-rejected'
            }
        ]
    },
    {
        link: `${match.path}/creative-settings?${new URLSearchParams(
            defaultSearchParams(true, true, false, false)
        ).toString()}`,
        icon: <Icons.BgColorsOutlined />,
        text: 'Creative settings',
        key: 'creative-settings-v2',
        menuTab: 'creative-settings'
    },
    {
        link: `${match.path}/accounts?${new URLSearchParams(defaultSearchParams()).toString()}`,
        icon: <Icons.ReconciliationOutlined />,
        text: 'Accounts',
        key: 'accounts-v2',
        menuTab: 'accounts'
    },
    {
        icon: <Icons.ShopOutlined />,
        text: 'Orders',
        key: 'orders-v2',
        menuTab: 'orders',
        link: `${match.path}/orders?${new URLSearchParams(defaultSearchParams()).toString()}`
    },
    {
        link: `${match.path}/privacy`,
        icon: <Icons.LockOutlined />,
        text: 'Customer privacy',
        key: 'privacy-v2',
        menuTab: 'privacy'
    },
    {
        icon: <Icons.SecurityScanOutlined />,
        text: 'Custom Creatives',
        key: 'custom-creatives-v2',
        menuTab: 'custom-creatives',
        link: `${match.path}/custom-creatives/in-review`,
        sublinks: [
            {
                link: `${match.path}/custom-creatives/in-review`,
                icon: <Icons.ExclamationCircleOutlined />,
                text: 'New',
                key: 'custom-creatives-review-new-v2',
                menuTab: 'custom-creatives-review-new'
            },
            {
                link: `${match.path}/custom-creatives/accepted`,
                icon: <Icons.CheckCircleOutlined />,
                text: 'Accepted',
                key: 'custom-creatives-review-accepted-v2',
                menuTab: 'custom-creatives-review-accepted'
            },
            {
                link: `${match.path}/custom-creatives/rejected`,
                icon: <Icons.StopOutlined />,
                text: 'Rejected',
                key: 'custom-creatives-review-rejected-v2',
                menuTab: 'custom-creatives-review-rejected'
            }
        ]
    }
];

import React from 'react';
import { Alert, Col, Row, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import logoImage from '../../../../images/klickly-logo-color.svg';
import './index.scss';

export const AuthLayout = inject('store')(
    observer(({ store, children, mainBoxClass = '' }) => {
        return (
            <div className='main-container'>
                <div className='main-background' />
                <div className={`main-box ${mainBoxClass}`}>
                    <Spin size='large' spinning={store.user.isLoading}>
                        <div className='main-logo'>
                            <Row type='flex' justify='center' align='top'>
                                <Col span={24}>
                                    <img className='main-logo__img' src={logoImage} alt='' />
                                </Col>
                            </Row>
                        </div>
                        <div className='main-content'>
                            <Row>
                                <Col span={24}>
                                    <div className='error-message-padding'>
                                        {store.user.error ? (
                                            <Alert message={store.user.error} type='error' showIcon />
                                        ) : null}
                                    </div>
                                    {children}
                                </Col>
                            </Row>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    })
);

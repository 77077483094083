import React, { Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import {
    BorderOuterOutlined,
    CommentOutlined,
    LogoutOutlined,
    MailOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Avatar, Col, Dropdown, Layout, Menu, message, Row, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import { adminAPI, checkMainMenuAccess } from '../../config';
import History from '../../utils/History';
import Request from '../../utils/Request';

export const HeaderLayout = inject('store')(
    observer(({ store }) => {
        const onLogoutClick = () => {
            History.push('/sign-in');
            Request.post(adminAPI.auth.logout, {});
        };

        const { user = {}, accesses } = store.user;
        const { firstName = 'Klickly', lastName = 'Admin', photo = null, ip } = user;

        const items = [
            {
                key: 'ip',
                label: (
                    <CopyToClipboard
                        text={ip}
                        onCopy={() => message.success('IP address successfully copied to clipboard')}
                    >
                        <span>
                            <BorderOuterOutlined /> IP: {ip}
                        </span>
                    </CopyToClipboard>
                )
            },
            {
                key: 'logout',
                label: (
                    <span onClick={() => onLogoutClick()}> {/* eslint-disable-line */}
                        <LogoutOutlined /> Logout
                    </span>
                )
            }
        ];

        const menuItems = [
            {
                key: 'brands',
                label: (
                    <Fragment>
                        <Link to='/brands'>
                            <TeamOutlined />
                            <span>Brands V1</span>
                        </Link>
                    </Fragment>
                )
            },
            {
                key: 'brands-v2',
                label: (
                    <Fragment>
                        <Link to='/brands-v2'>
                            <TeamOutlined />
                            <span>Brands V2</span>
                        </Link>
                    </Fragment>
                )
            },
            {
                key: 'kcp',
                label: (
                    <Fragment>
                        <Link to='/kcp'>
                            <CommentOutlined />
                            <span>KCP admin</span>
                        </Link>
                    </Fragment>
                )
            },
            {
                key: 'notifications',
                label: (
                    <Fragment>
                        <Link to='/notifications'>
                            <MailOutlined />
                            <span>Notifications admin</span>
                        </Link>
                    </Fragment>
                )
            },
            {
                key: 'system',
                label: (
                    <Fragment>
                        <Link to='/system'>
                            <SettingOutlined />
                            <span>System admin</span>
                        </Link>
                    </Fragment>
                )
            }
        ];

        const selectedKey = store.pathname.split('/')[1] || 'brands';

        return (
            <Layout.Header className='header'>
                <Row>
                    <Col span={18}>
                        <Menu
                            selectedKeys={[selectedKey]}
                            mode='horizontal'
                            items={menuItems.filter((item) => {
                                return checkMainMenuAccess(item.key, accesses);
                            })}
                        />
                    </Col>
                    <Col span={6}>
                        <div className='right'>
                            {firstName ? (
                                <Dropdown menu={{ items }}>
                                    <span className='action account'>
                                        <Avatar size='small' className='avatar' icon={<UserOutlined />} src={photo} />
                                        <span className='name'>
                                            {firstName} {lastName}
                                        </span>
                                    </span>
                                </Dropdown>
                            ) : (
                                <Spin size='small' style={{ marginLeft: 8 }} />
                            )}
                        </div>
                    </Col>
                </Row>
            </Layout.Header>
        );
    })
);

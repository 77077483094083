import { message } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const OrdersReviewStore = types
    .model('OrdersReviewStore', {
        isLoading: false,
        data: types.optional(
            types.model({
                total: 0,
                accounts: types.array(types.frozen())
            }),
            {
                total: 0,
                accounts: []
            }
        )
    })
    .views(() => ({}))
    .actions((self) => {
        const fetch = flow(function* fetch(search) {
            self.isLoading = true;
            try {
                const data = yield Request.get(adminAPI.orders.pendingReview.list, { search });
                self.data.total = data.total;
                self.data.accounts = data.accounts;
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        return {
            fetch
        };
    });

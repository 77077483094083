import { types } from 'mobx-state-tree';
import { AccountPromotionsListStore } from './AccountPromotionsListStore';
import { AccountsListStore } from './AccountsListStore';
import { AccountStore } from './AccountStore';
import { BlockedEmailsStore } from './BlockedEmailsStore';
import { BlockedProductsStore } from './BlockedProductsStore';
import { CreativeSettingsStore } from './CreativeSettingsStore';
import { CustomerPrivacyDataStore } from './CustomerPrivacyDataStore';
import { DataPrivacyStore } from './DataPrivacyStore';
import { GiftlyCustomerStore } from './GiftlyCustomerStore';
import { GiftlyGSProductStore } from './GiftlyGSProductStore';
import { OrderHistoryOneStore } from './OrderHistoryOneStore';
import { OrderHistoryStore } from './OrderHistoryStore';
import { OrderInsightsStore } from './OrderInsightsStore';
import { OrderReviewStore } from './OrderReviewStore';
import { OrdersReviewByAccountStore } from './OrdersReviewByAccountStore';
import { OrdersReviewStore } from './OrdersReviewStore';
import { OrderTrackingNumberStore } from './OrderTrackingNumberStore';
import { PromotionPageStore } from './PromotionPageStore';
import { PromotionsListStore } from './PromotionsListStore';
import { RefundModalStore } from './RefundModalStore';
import { UserStore } from './UserStore';

export const RootStore = types
    .model('RootStore', {
        pathname: window.location.pathname,

        user: types.optional(UserStore, {}),
        account: types.optional(AccountStore, {}),
        accountsList: types.optional(AccountsListStore, {}),
        accountPromotionsList: types.optional(AccountPromotionsListStore, {}),
        refundModal: types.optional(RefundModalStore, {}),
        promotionsList: types.optional(PromotionsListStore, {}),
        promotionPage: types.optional(PromotionPageStore, {}),
        customerPrivacyData: types.optional(CustomerPrivacyDataStore, {}),
        blockedEmails: types.optional(BlockedEmailsStore, {}),
        blockedProducts: types.optional(BlockedProductsStore, {}),
        creativeSettings: types.optional(CreativeSettingsStore, {}),
        ordersReview: types.optional(OrdersReviewStore, {}),
        orderReview: types.optional(OrderReviewStore, {}),
        ordersReviewByAccount: types.optional(OrdersReviewByAccountStore, {}),
        orderTrackingNumber: types.optional(OrderTrackingNumberStore, {}),
        orderHistory: types.optional(OrderHistoryStore, {}),
        orderHistoryOne: types.optional(OrderHistoryOneStore, {}),
        orderInsightsStore: types.optional(OrderInsightsStore, {}),

        giftlyCustomerStore: types.optional(GiftlyCustomerStore, {}),
        giftlyGSProductStore: types.optional(GiftlyGSProductStore, {}),
        dataPrivacy: types.optional(DataPrivacyStore, {})
    })
    .views((self) => ({}))
    .actions((self) => {
        const setPathName = (path) => {
            self.pathname = path;
        };

        return { setPathName };
    });

import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { meta } from './common';

export const AccountPromotionsListStore = types
    .model('AccountPromotionsListStore', {
        isLoading: false,
        meta,
        list: types.optional(types.array(types.frozen()), []),
        search: '',
        page: 1,
        status: 'in-review'
    })
    .views((self) => ({}))
    .actions((self) => {
        const clearFetchParams = () => {
            self.search = '';
            self.page = 1;
        };

        const changeStatus = (status) => {
            self.page = 1;
            self.status = status;
        };

        const changePage = (page) => {
            self.page = page;
        };

        const changeSearch = (search) => {
            self.page = 1;
            self.search = search;
        };

        const fetch = flow(function* fetch() {
            self.isLoading = true;
            try {
                self.list = [];
                const data = yield Request.get(adminAPI.accountPromotions.review.list, {
                    status: self.status,
                    page: self.page,
                    search: self.search
                });
                self.list = data.list;
                self.meta.setMeta(data.meta);
            } catch (error) {
                // nothing to do
            }
            self.isLoading = false;
        });

        const changePromotionStatusBulk = flow(function* changePromotionStatusBulk(accountId, fromStatus, toStatus) {
            self.isLoading = true;

            try {
                yield Request.post(adminAPI.accountPromotions.review.bulk(accountId), { fromStatus, toStatus });
            } catch (err) {
                // nothing to do
            }

            self.isLoading = false;
        });

        return {
            clearFetchParams,
            changeStatus,
            changePage,
            changeSearch,
            fetch,
            changePromotionStatusBulk
        };
    });

import { message } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const OrderReviewStore = types
    .model('OrderReviewStore', {
        isLoading: false,
        sendRefundEmail: true
    })
    .views((self) => ({}))
    .actions((self) => {
        const setSendRefundEmail = (status) => {
            self.sendRefundEmail = status;
        };

        const proceedOrder = flow(function* proceedOrder({ _id, orderNumber, onClose }) {
            self.isLoading = true;
            try {
                yield Request.post(adminAPI.orders.pendingReview.proceed(_id));
                message.success(`Order ${orderNumber} has been proceed successfully!`);
                onClose(true);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const refundOrder = flow(function* refundOrder({ _id, orderNumber, onClose }) {
            self.isLoading = true;
            try {
                yield Request.post(adminAPI.orders.pendingReview.refund(_id), {
                    sendRefundEmail: self.sendRefundEmail
                });
                message.success(`Order ${orderNumber} has been refunded successfully!`);
                onClose(true);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        return {
            setSendRefundEmail,
            proceedOrder,
            refundOrder
        };
    });

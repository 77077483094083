import React from 'react';
import { Button, Result } from 'antd';
import History from '../../utils/History';

const NotFoundComponent = ({ match }) => {
    return (
        <Result
            status='404'
            title='Page not found'
            subTitle='Sorry, the page you visited does not exist.'
            extra={
                <Button onClick={() => History.push(match.path)} type='primary'>
                    Back Home
                </Button>
            }
        />
    );
};

export default NotFoundComponent;

import { message } from 'antd';
import { flow, getParent, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { meta } from './common';

export const BlockedProductsStore = types
    .model('BlockedProductsStore', {
        loaders: types.optional(
            types.model({
                products: types.boolean,
                addForm: types.boolean
            }),
            {
                products: false,
                addForm: false
            }
        ),
        data: types.optional(
            types.model({
                meta,
                list: types.array(types.frozen()),
                page: types.number
            }),
            {
                list: [],
                page: 1
            }
        ),
        addFormVisible: false
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get actionUrl() {
            return adminAPI.account.blockedProducts.actionUrl(self.root.account.getValue('_id'));
        }
    }))
    .actions((self) => {
        const changeForm = (form) => {
            self.currentForm = form;
        };

        const toggleAddForm = (status) => {
            self.addFormVisible = status;
        };

        const handleTableChange = (page) => {
            self.data.page = page;
            self.fetch();
        };

        const fetch = flow(function* fetch() {
            self.loaders.products = true;
            try {
                const { meta, data } = yield Request.get(self.actionUrl, {
                    page: self.data.page
                });
                self.data.meta = meta;
                self.data.list = data;
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders.products = false;
            }
        });

        const removeItem = flow(function* removeItem(id) {
            self.loaders.products = true;
            try {
                yield Request.delete(`${self.actionUrl}/${id}`);
                yield self.fetch();
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders.products = false;
            }
        });

        const submitCreate = flow(function* submitCreate(data, onClose) {
            self.loaders.addForm = true;
            try {
                yield Request.post(`${self.actionUrl}`, data);
                onClose(true);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders.addForm = false;
            }
        });

        return {
            changeForm,
            toggleAddForm,
            handleTableChange,
            fetch,
            removeItem,
            submitCreate
        };
    });

import { message } from 'antd';
import * as upperFirst from 'lodash/upperFirst';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { meta } from './common';

export const AccountsListStore = types
    .model('AccountsListStore', {
        isLoading: false,
        isLoadingStats: false,
        list: types.optional(types.array(types.frozen()), []),
        meta,
        payload: types.optional(
            types.model({
                search: types.maybeNull(types.string),
                page: types.number,
                statuses: types.array(types.frozen()),
                checks: types.array(types.frozen())
            }),
            {
                search: null,
                page: 1,
                statuses: [],
                checks: []
            }
        ),
        stats: types.frozen()
    })
    .views((self) => ({
        get statusFilter() {
            return self.stats && self.stats.list
                ? self.stats.list.map((l) => {
                      return { text: upperFirst(l), value: l };
                  })
                : [];
        },
        get statuses() {
            if (self.payload.statuses.length) {
                return self.payload.statuses;
            }

            return null;
        },
        get checks() {
            if (self.payload.checks.length) {
                return self.payload.checks;
            }

            return null;
        },
        get totalDocuments() {
            if (self.meta && self.meta.totalDocuments !== undefined) {
                return self.meta.totalDocuments;
            }

            return 'loading...';
        }
    }))
    .actions((self) => {
        const clearFetchParams = () => {
            self.payload.search = '';
            self.payload.page = 1;
            self.payload.statuses = [];
        };

        const changePage = (page) => {
            self.payload.page = page;
        };

        const changeStatuses = (statuses = []) => {
            self.payload.page = 1;
            self.payload.statuses = statuses;
        };

        const changeChecks = (checks = []) => {
            self.payload.page = 1;
            self.payload.checks = checks;
        };

        const changeSearch = (search) => {
            self.payload.page = 1;
            self.payload.search = search;
        };

        const fetch = flow(function* fetch() {
            self.isLoading = true;
            try {
                self.list = [];
                const result = yield Request.get(adminAPI.account.list, self.payload);
                self.list = result.data;
                self.meta = result.meta;
            } catch (error) {
                message.error(error.message);
            }
            self.isLoading = false;
            return self.payload;
        });

        const fetchStatusStats = flow(function* fetchStatusStats() {
            self.isLoadingStats = true;
            try {
                self.stats = yield Request.get(adminAPI.account.statusStats);
            } catch (error) {
                message.error(error.message);
            }
            self.isLoadingStats = false;
        });

        return {
            clearFetchParams,
            changeSearch,
            changePage,
            changeStatuses,
            changeChecks,
            fetch,
            fetchStatusStats
        };
    });

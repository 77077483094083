import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const PromotionPageStore = types
    .model('PromotionPageStore', {
        promotion: types.optional(types.frozen(), {
            title: ''
        }),
        isLoading: false,
        promotionStatus: '',
        isShowReasonInput: false,
        reason: ''
    })
    .views((self) => ({}))
    .actions((self) => {
        const changeStatus = (promotionStatus) => {
            self.promotionStatus = promotionStatus;
        };

        const changeReason = (reason) => {
            self.reason = reason;
        };

        const showReasonInput = () => {
            self.isShowReasonInput = true;
        };

        const hideReasonInput = () => {
            self.isShowReasonInput = false;
        };

        const fetch = flow(function* fetch(promotionId) {
            self.isLoading = true;
            try {
                const data = yield Request.get(adminAPI.accountPromotions.review.get(promotionId));
                self.promotion = data;
                self.reason = data.rejectedReason;
                self.promotionStatus = data.status;
            } catch (error) {
                // nothing to do
            }
            self.isLoading = false;
        });

        const submitStatusReview = flow(function* submitStatusReview(promotionId) {
            if (self.promotionStatus) {
                self.isLoading = true;
                try {
                    const data = { status: self.promotionStatus };

                    if (self.reason) {
                        data.reason = self.reason;
                    }

                    yield Request.put(adminAPI.accountPromotions.review.changeStatus(promotionId), data);

                    self.promotionStatus = '';
                    self.hideReasonInput();
                    self.reason = '';
                } catch (err) {
                    // nothing to do
                }

                self.isLoading = false;
            }
        });

        return {
            changeStatus,
            changeReason,
            showReasonInput,
            hideReasonInput,
            fetch,
            submitStatusReview
        };
    });

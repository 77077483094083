import React, { Suspense, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Spin } from 'antd';
import { Provider } from 'mobx-react';
import { ComponentAuth } from './components/Auth';
import { ComponentMainWrapper } from './components/MainWrapper';
import PageNotFound from './pages/_NotFound';
import { PageMFA, PageSignIn } from './pages/Auth';
import { RootStore } from './stores/RootStore';
import History from './utils/History';
import './styles/app.scss';

const PageBrands = React.lazy(() => import('./pages/Brands'));
const PageBrandsV2 = React.lazy(() => import('./pages/BrandsV2'));
const PageKCP = React.lazy(() => import('./pages/KCP'));
const PageNotifications = React.lazy(() => import('./pages/Notifications'));
const PageSystem = React.lazy(() => import('./pages/System'));

/**
 * This will replace mobx stores as v1 will be redundant
 */
const queryClient = new QueryClient();

function handleUpdate() {
    const { action } = this.state.location;
    if (action === 'PUSH') {
        window.scrollTo(0, 0);
    }
}

const SuspenseFallback = () => {
    return (
        <div className='placeholder'>
            <div className='placeholder-spinner'>
                <Spin size='large' />
            </div>
        </div>
    );
};

const App = () => {
    const store = useMemo(() => RootStore.create({}, {}), []);

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Router history={History.instance} onUpdate={handleUpdate}>
                    <Switch>
                        <Route path='/sign-in' component={PageSignIn} />
                        <Route
                            path='/'
                            render={() => (
                                <ComponentAuth>
                                    <ComponentMainWrapper>
                                        <Suspense fallback={<SuspenseFallback />}>
                                            <Switch>
                                                <Route path='/mfa' component={PageMFA} />

                                                <Redirect exact from='/' to='/brands' />
                                                <Route path='/brands' component={PageBrands} />
                                                <Route path='/brands-v2' component={PageBrandsV2} />
                                                <Route path='/kcp' component={PageKCP} />
                                                <Route path='/notifications' component={PageNotifications} />
                                                <Route path='/system' component={PageSystem} />
                                                <Route component={PageNotFound} />
                                            </Switch>
                                        </Suspense>
                                    </ComponentMainWrapper>
                                </ComponentAuth>
                            )}
                        />
                    </Switch>
                </Router>
            </QueryClientProvider>
        </Provider>
    );
};

const container = document.getElementById('klickly-admin-root');
const root = createRoot(container);
root.render(<App />);

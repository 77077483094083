export const EVENT_SHEET = 'customer_journey_info';

export const XLSX_EXPORT_COLUMNS = [
    {
        key: 'general_information',
        title: 'General Information',
        children: [
            { value: 'klicklyId', label: 'Klickly ID' },
            { value: 'shopifyId', label: 'Shopify ID' },
            { value: 'orderId', label: 'Order ID' },
            { value: 'campaign', label: 'Campaign' },
            { value: 'createdAt', label: 'Created At' },
            { value: 'processedAt', label: 'Processed At' }
        ]
    },
    {
        key: 'order_details',
        title: 'Order Details',
        children: [
            { value: 'orderItems', label: 'Order items' },
            { value: 'subTotal', label: 'Sub-total' },
            { value: 'shipping', label: 'Shipping' },
            { value: 'tax', label: 'Tax' },
            { value: 'totalPrice', label: 'Total Price' },
            { value: 'discountKlickly', label: 'Discount (Klickly)' },
            { value: 'customerPrice', label: 'Customer Price' },
            { value: 'financialStatus', label: 'Financial Status' },
            { value: 'commission', label: 'Commission' },
            { value: 'commissionType', label: 'Commission type' }
        ]
    },
    {
        key: 'customer_information',
        title: 'Customer Information',
        children: [
            { value: 'customerType', label: 'Customer Type' },
            { value: 'email', label: 'Email' },
            { value: 'phone', label: 'Phone' },
            { value: 'shippingAddress', label: 'Shipping Address' },
            { value: 'billingAddress', label: 'Billing Address' }
        ]
    },
    {
        key: 'engagement_details',
        title: 'Engagement Details',
        children: [
            { value: 'totalEngagements', label: 'Total Engagements' },
            { value: 'daysToConversion', label: 'Days to conversion' },
            { value: 'daysFromFirstEngagement', label: 'Days from first engagement' },
            { value: 'dateOfLastEngagement', label: 'Date of last engagement' }
        ]
    },
    {
        key: EVENT_SHEET,
        title: 'Customer Journey Info',
        children: [
            { value: 'klicklyIdJourney', label: 'Klickly ID' },
            { value: 'shopifyIdJourney', label: 'Shopify ID' },
            { value: 'externalOrderId', label: 'externalOrderId' },
            { value: 'eventTime', label: 'Event Time' },
            { value: 'highlightKlicklyEngagement', label: 'Highlight Klickly Engagement' },
            { value: 'platform', label: 'Platform' },
            { value: 'actionType', label: 'Action Type' },
            { value: 'device', label: 'Device' },
            { value: 'osType', label: 'OS Type' },
            { value: 'viewport', label: 'Viewport' },
            { value: 'referrer', label: 'Referrer' },
            { value: 'hostname', label: 'Hostname' },
            { value: 'pathname', label: 'Pathname' },
            { value: 'queryParam', label: 'Query Param' }
        ]
    }
];

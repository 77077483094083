import * as get from 'lodash/get';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import History from '../utils/History';
import Request from '../utils/Request';

export const UserStore = types
    .model('UserStore', {
        isLoading: false,
        error: '',
        user: types.maybeNull(types.frozen()),
        mfaData: types.maybeNull(types.frozen()),
        ipCheck: false,
        clientIp: ''
    })
    .views((self) => ({
        get mfaSecretExists() {
            return self.user.mfaSecretExists;
        },
        get accesses() {
            return get(self.user, 'accesses', []);
        }
    }))
    .actions((self) => {
        const setUser = (user) => {
            self.user = user;
        };

        const enableLoading = () => {
            self.isLoading = true;
            self.error = '';
        };

        const checkIfMFARequired = () => {
            if (self.user.mfaSecretExists) {
                History.push('/mfa/verify');
            } else {
                History.push('/mfa/setup');
            }
        };

        const fetch = flow(function* fetch() {
            try {
                const {
                    user = null,
                    ipChecked = false,
                    mfaVerified = false
                } = yield Request.get(adminAPI.adminUser.me);

                self.setUser(user);
                self.ipCheck = ipChecked;
                if (!mfaVerified) {
                    self.checkIfMFARequired();
                }
                return true;
            } catch (error) {
                // nothing to do
            }

            return false;
        });

        const signIn = flow(function* signIn(values) {
            enableLoading();
            try {
                const { user, mfaVerified } = yield Request.post(adminAPI.auth.signIn, values);
                self.setUser(user);

                if (!mfaVerified) {
                    self.checkIfMFARequired();
                } else {
                    History.push('/');
                }
            } catch (error) {
                self.error = get(error, 'response.data.message', error.message);
            }

            self.isLoading = false;
        });

        const fetchMFASetupData = flow(function* getMFASetupData() {
            enableLoading();
            try {
                self.mfaData = yield Request.get(adminAPI.adminUser.mfa.setup);
            } catch (error) {
                self.error = error.message;
            }
            self.isLoading = false;
        });

        const verifyMFAData = flow(function* verifyMFAData(token) {
            enableLoading();
            try {
                const payload = { token };
                if (self.mfaData?.secret) {
                    payload.secret = self.mfaData.secret;
                }
                const { user, clientIp } = yield Request.post(adminAPI.adminUser.mfa.verify, payload);
                if (!user.isWhitelistedIP) {
                    self.clientIp = clientIp;
                    History.push('/mfa/ip-whitelist');
                } else {
                    History.push('/');
                }
            } catch (error) {
                self.error = error.message;
            }
            self.isLoading = false;
        });

        const ipWhitelist = flow(function* ipWhitelist() {
            enableLoading();
            try {
                yield Request.post(adminAPI.adminUser.mfa.ipWhitelist, {
                    ip: self.clientIp
                });
                History.push('/');
            } catch (error) {
                self.error = error.message;
            }
            self.isLoading = false;
        });

        const googleProcessCode = flow(function* processGoogleCode(code) {
            enableLoading();
            try {
                const { user, mfaVerified } = yield Request.post(adminAPI.auth.google.callback, { code });
                self.setUser(user);

                if (!mfaVerified) {
                    self.checkIfMFARequired();
                } else {
                    History.push('/');
                }
            } catch (error) {
                self.error = error.message;
            }
            self.isLoading = false;
        });

        const googleRedirectUrl = flow(function* googleRedirectUrl() {
            enableLoading();
            try {
                const redirectUrl = yield Request.get(adminAPI.auth.google.redirectUrl);
                window.open(redirectUrl, '_self');
            } catch (error) {
                self.isLoading = false;
                self.error = error.message;
            }
        });

        return {
            setUser,
            checkIfMFARequired,
            fetch,
            signIn,
            fetchMFASetupData,
            verifyMFAData,
            ipWhitelist,
            googleProcessCode,
            googleRedirectUrl
        };
    });

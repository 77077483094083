import React, { Fragment } from 'react';
import { Layout } from 'antd';
import { HeaderLayout } from '../../components/Header';
import { SiderMenu } from '../../components/LeftMenu';
import NotFoundComponent from '../../components/NotFound';

const PageNotFound = ({ match }) => {
    return (
        <Fragment>
            <SiderMenu menus={[]} />
            <Layout>
                <HeaderLayout />
                <Layout.Content className='layout-content'>
                    <NotFoundComponent match={match} />
                </Layout.Content>
            </Layout>
        </Fragment>
    );
};

export default PageNotFound;

import React, { Fragment } from 'react';

export const prepareDollarAmount = (amount) => {
    return numberWithCommas(numberWithCents(mathRound(amount)));
};

export const centsToNumberWithDollar = (x) => {
    let dollar = '$';
    if (x < 0) {
        x *= -1;
        dollar = '-$';
    }
    return `${dollar}${numberWithCents(x / 100)}`;
};

export const dollarRender = (text) => {
    if (text === null) {
        return '-';
    }
    const amount = parseFloat(text);
    if (amount < 0) {
        return `-$${amount * -1}`;
    }
    return `$${amount}`;
};

export const numberWithCommas = (x) => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithCents = (x) => {
    if (x !== null && x !== 0) {
        return (Math.round(x * 100) / 100).toFixed(2);
    }

    return '0.00';
};

export const formatShippingAddress = (address) => {
    if (address) {
        return (
            <Fragment>
                <div>
                    First name: <b>{address.firstName}</b>
                </div>
                <div>
                    Last name: <b>{address.lastName}</b>
                </div>
                <div>
                    Address: <b>{address.address1}</b>
                </div>
                <div>
                    Address2: <b>{address.address2}</b>
                </div>
                <div>
                    City: <b>{address.city}</b>
                </div>
                <div>
                    State: <b>{address.province}</b>
                </div>
                <div>
                    Country: <b>{address.country}</b>
                </div>
                <div>
                    Country code: <b>{address.countryCode}</b>
                </div>
                <div>
                    Zip code: <b>{address.zipCode}</b>
                </div>
                <div>
                    Phone: <b>{address.phone}</b>
                </div>
            </Fragment>
        );
    }
    return <div>No data</div>;
};

export const formatPricesBlock = (prices) => {
    return prices ? (
        <Fragment>
            <div>
                Shipping: <b>${prices.shipping}</b>
            </div>
            <div>
                Taxes: <b>${prices.taxes}</b>
            </div>
            <div>
                Products price: <b>${prices.price}</b>
            </div>
            <div>
                Total: <b>${prices.total}</b>
                <br />
                {prices.paidBy ? (
                    <small>
                        Klickly: ${prices.paidBy.klickly}, Brand: ${prices.paidBy.brand}
                    </small>
                ) : (
                    ''
                )}
            </div>
        </Fragment>
    ) : (
        <div>No data</div>
    );
};

export const prepareImageUrl = (src, pattern) => {
    return src.replace(
        /(\.jpg(?=[^.jpg]*$)|\.png(?=[^.png]*$)|\.gif(?=[^.gif]*$)|\.jpeg(?=[^.jpeg]*$))/,
        pattern || '_180x$1'
    );
};

export const mathRound = (value, factor = 100) => {
    return Math.round(value * factor) / factor;
};

export const roundToLocaleString = (value) => {
    const maxDigits = value % 1 === 0 ? 0 : 2;

    const toLocaleStringOptions = {
        minimumFractionDigits: maxDigits,
        maximumFractionDigits: maxDigits
    };

    return Number(value || 0)
        .toLocaleString('en-US', toLocaleStringOptions)
        .replace(/,$/, '.');
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import History from '../../../../utils/History';
import { AuthLayout } from '../../components/Layout';

export const PageMFASetup = inject('store')(
    observer(({ store }) => {
        const [mfaToken, setMfaToken] = useState('');
        useEffect(() => {
            if (store.user.mfaSecretExists) {
                History.push('/');
            } else {
                store.user.fetchMFASetupData();
            }
        }, []);

        const onMFATokenChange = (e) => {
            setMfaToken(e.target.value);
        };

        return (
            <AuthLayout mainBoxClass='mfa-setup'>
                <Typography.Title level={4}>Two-Factor Authentication (2FA)</Typography.Title>
                <Divider />
                <Typography.Title level={5}>Configuring Google Authenticator or Authy</Typography.Title>
                <div>
                    <li>Install Google Authenticator (IOS - Android) or Authy (IOS - Android).</li>
                    <li>In the authenticator app, select &quot;+&quot; icon.</li>
                    <li>
                        Select &quot;Scan a barcode (or QR code)&quot; and use the phone&apos;s camera to scan this
                        barcode.
                    </li>
                </div>
                <Divider />
                <Typography.Title level={5}>Scan QR Code</Typography.Title>
                <div style={{ textAlign: 'center' }}>
                    {store.user.mfaData?.qr ? <img src={store.user.mfaData.qr} alt='QR Code' /> : null}
                </div>
                <Divider />
                <Typography.Title level={5}>Or Enter Code Into Your App</Typography.Title>
                <div>Secret key: {store.user.mfaData?.secret}</div>
                <Divider />
                <Typography.Title level={5}>Verify Code</Typography.Title>
                <div>For changing the setting, please verify the authentication code:</div>
                <Input onChange={onMFATokenChange} placeholder='Enter code (XXXXXX)' />
                <Divider />
                <Button
                    onClick={() => store.user.verifyMFAData(mfaToken)}
                    size='large'
                    type='primary'
                    htmlType='submit'
                    className='login-form-button'
                >
                    Verify & Activate
                </Button>
            </AuthLayout>
        );
    })
);

import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { inject, observer } from 'mobx-react';
import History from '../../utils/History';

export const ComponentMainWrapper = inject('store')(
    observer(({ store, children }) => {
        useEffect(() => {
            History.instance.listen((path) => {
                store.setPathName(path.pathname);
            });
    }, []); // eslint-disable-line

        return <Layout className='vh-height'>{children}</Layout>;
    })
);

import React from 'react';
import { matchPath } from 'react-router'; // eslint-disable-line
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { inject, observer } from 'mobx-react';
import logo from '../../images/klickly-logo-white.svg';
import './index.scss';

export const SiderMenu = inject('store')(
    observer(({ store, menus, mapPathToKey = {} }) => {
        const generateSubmenuTitle = (menu) => {
            return (
                <span>
                    {menu.icon}
                    <span>{menu.text}</span>
                </span>
            );
        };

        const generateMenu = (menus) => {
            return menus.map((menu) => {
                if (menu.sublinks) {
                    return {
                        key: menu.menuTab,
                        label: generateSubmenuTitle(menu),
                        children: generateMenu(menu.sublinks)
                    };
                }
                if (menu.divider) {
                    return {
                        type: 'divider',
                        className: 'divider-space'
                    };
                }

                let link;
                if (menu.href) {
                    link = (
                        <a href={menu.href} target={menu.target || '_self'}>
                            {menu.icon}
                            <span>{menu.text}</span>
                        </a>
                    );
                } else {
                    link = (
                        <Link to={menu.link}>
                            {menu.icon}
                            <span>{menu.text}</span>
                        </Link>
                    );
                }

                return {
                    key: menu.menuTab,
                    label: link
                };
            });
        };

        const defaultOpenKeys = (menus) => {
            return menus.reduce((acc, menu) => {
                if (menu.sublinks) {
                    acc.push(menu.menuTab);
                }
                return acc;
            }, []);
        };

        const getSelectedItem = () => {
            const currentLocation = store.pathname.split('/')[2] || store.pathname.split('/')[1];
            for (const [key, value] of Object.entries(mapPathToKey)) {
                if (matchPath(store.pathname, { path: key, exact: true })) {
                    return value;
                }
            }
            return currentLocation;
        };

        return (
            <Layout.Sider>
                <div className='logo'>
                    <img src={logo} alt='logo' className='logo__img' />
                </div>
                <Menu
                    className='left-menu'
                    theme='dark'
                    mode='inline'
                    selectedKeys={[getSelectedItem()]}
                    defaultOpenKeys={defaultOpenKeys(menus)}
                    items={generateMenu(menus)}
                />
            </Layout.Sider>
        );
    })
);

import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { meta } from './common';

export const PromotionsListStore = types
    .model('PromotionsListStore', {
        isLoading: true,
        meta,
        promotions: types.optional(types.array(types.frozen()), []),
        account: types.optional(types.frozen(), {
            name: ''
        }),
        search: '',
        page: 1,
        status: 'in-review',
        accountId: types.maybeNull(types.frozen())
    })
    .views((self) => ({}))
    .actions((self) => {
        const changeAccountId = (accountId) => {
            self.accountId = accountId;
        };

        const changeStatus = (status) => {
            self.page = 1;
            self.status = status;
        };

        const changePage = (page) => {
            self.page = page;
        };

        const changeSearch = (search) => {
            self.page = 1;
            self.search = search;
        };

        const clearData = () => {
            self.promotions = [];
            self.meta = {
                currentPage: 1,
                totalDocuments: 0
            };
            self.account = {};
        };

        const fetch = flow(function* fetch() {
            self.isLoading = true;
            try {
                const data = yield Request.get(adminAPI.accountPromotions.review.accountList(self.accountId), {
                    status: self.status,
                    page: self.page,
                    search: self.search
                });
                self.promotions = data.list;
                self.meta = data.meta;
                self.account = data.account;
            } catch (error) {
                // nothing to do
            }
            self.isLoading = false;
        });

        return {
            changeAccountId,
            changeStatus,
            changePage,
            changeSearch,
            clearData,
            fetch
        };
    });

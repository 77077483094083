import * as isEmpty from 'lodash/isEmpty';
import { types } from 'mobx-state-tree';

const defaultValues = {
    currentPage: 1,
    totalDocuments: 0
};

export const meta = types.optional(
    types
        .model({
            currentPage: types.maybeNull(types.number),
            totalDocuments: types.maybeNull(types.number)
        })
        .actions((self) => {
            const setMeta = (data) => {
                if (data && !isEmpty(data)) {
                    self.currentPage = data.currentPage || defaultValues.currentPage;
                    self.totalDocuments = data.totalDocuments || defaultValues.totalDocuments;
                } else {
                    self.currentPage = defaultValues.currentPage;
                    self.totalDocuments = defaultValues.totalDocuments;
                }
            };

            return { setMeta };
        }),
    defaultValues
);

import { notification } from 'antd';
import * as get from 'lodash/get';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const RefundModalStore = types
    .model('RefundModalStore', {
        visible: false,
        item: types.maybeNull(types.frozen()),
        data: types.optional(
            types.model({
                refundAmount: types.optional(types.number, 0),
                inKlickly: types.boolean,
                inStripe: types.boolean
            }),
            {
                refundAmount: 0,
                inKlickly: true,
                inStripe: true
            }
        )
    })
    .views((self) => ({}))
    .actions((self) => {
        const changeVisibility = (visible) => {
            self.visible = visible;
        };

        const onChangeValue = (variable, value) => {
            self.data[variable] = value;
        };

        const clearData = () => {
            self.data = {
                refundAmount: 0,
                inKlickly: true,
                inStripe: true
            };
        };

        const onCancel = () => {
            self.clearData();
            self.item = null;
            self.changeVisibility(false);
        };

        const openRefundModal = (item) => {
            self.item = item;
            self.changeVisibility(true);
        };

        const onSubmit = flow(function* onSubmit() {
            let result = false;
            self.data.itemId = self.item._id;
            try {
                self.changeVisibility(false);
                if (self.data.refundAmount !== 0) {
                    yield Request.post(adminAPI.account.invoices.refund, self.data);

                    notification.success({
                        message: 'Completed!',
                        description: `$${self.data.refundAmount} refunded from invoice item #${self.item.itemNumber}`
                    });

                    result = true;
                }
            } catch (error) {
                notification.error({
                    duration: 0,
                    message: 'Error :(',
                    description: get(error, 'response.data.message', error.message)
                });
            }

            self.item = null;
            self.clearData();

            return result;
        });

        return {
            changeVisibility,
            onChangeValue,
            clearData,
            onCancel,
            openRefundModal,
            onSubmit
        };
    });

import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { inject, observer } from 'mobx-react';

export const ComponentAuth = inject('store')(
    observer(({ store, children }) => {
        const [isLoading, setIsLoading] = useState(true);

        const fetchData = async () => {
            const result = await store.user.fetch();
            if (result) {
                setIsLoading(false);
            }
        };

        useEffect(() => {
            fetchData();
    }, []); // eslint-disable-line

        return isLoading ? (
            <div className='placeholder'>
                <div className='placeholder-spinner'>
                    <Spin size='large' />
                </div>
            </div>
        ) : (
            children
        );
    })
);

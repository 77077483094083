import { message } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const OrderTrackingNumberStore = types
    .model('OrderTrackingNumberStore', {
        isLoading: false,
        orderNumber: types.maybeNull(types.frozen()),
        data: types.maybeNull(types.frozen()),
        accountArray: types.optional(types.array(types.frozen()), []),
        accountId: types.maybeNull(types.frozen())
    })
    .views((self) => ({}))
    .actions((self) => {
        const handleAccountChange = (value) => {
            self.accountId = value;
        };

        const setOrderNumber = (orderNumber) => {
            self.orderNumber = orderNumber;
        };

        const getTN = flow(function* getTN() {
            self.isLoading = true;
            try {
                self.data = yield Request.get(adminAPI.orders.trackingNumber, {
                    accountId: self.accountId,
                    orderNumber: self.orderNumber
                });
            } catch (error) {
                self.data = null;
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const handleAccountSearch = flow(function* (value) {
            if (value.length <= 2) {
                return true;
            }
            try {
                const { data } = yield Request.get(adminAPI.account.list, {
                    search: value,
                    status: 'active',
                    page: 1,
                    limit: 1000
                });
                self.accountArray = data;
            } catch (error) {
                message.error(error.message);
            }
        });

        return {
            handleAccountChange,
            setOrderNumber,
            getTN,
            handleAccountSearch
        };
    });

import { message } from 'antd';
import * as get from 'lodash/get';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import History from '../utils/History';
import Request from '../utils/Request';
import { meta } from './common';

export const CreativeSettingsStore = types
    .model('CreativeSettingsStore', {
        loaders: types.optional(
            types.model({
                page: types.boolean,
                details: types.boolean
            }),
            {
                page: false,
                details: false
            }
        ),
        page: 1,
        search: '',
        list: types.optional(types.array(types.frozen()), []),
        meta,
        selectedAccount: types.maybeNull(types.frozen()),
        details: types.optional(
            types.model({
                logoStatus: types.maybeNull(types.frozen()),
                offerMessageStatus: types.maybeNull(types.frozen())
            }),
            {
                logoStatus: null,
                offerMessageStatus: null
            }
        )
    })
    .views((self) => ({}))
    .actions((self) => {
        const changeAccount = (account) => {
            self.selectedAccount = account;
        };

        const checkLogoStatus = () => {
            self.details.logoStatus = get(self.selectedAccount, 'creativeSettings.statuses.logoImage', null);
            if (self.details.logoStatus === 'in-review') {
                self.details.logoStatus = null;
            }
        };

        const checkOfferMessageStatus = () => {
            self.details.offerMessageStatus = get(self.selectedAccount, 'creativeSettings.statuses.offerMessage', null);
            if (self.details.offerMessageStatus === 'in-review') {
                self.details.offerMessageStatus = null;
            }
        };

        const onSearchChange = (value) => {
            self.search = value;
            self.page = 1;
            self.fetch();
            History.push('?page=1');
        };

        const changePage = (page) => {
            self.page = page;
        };

        const handleTableChange = (pagination) => {
            self.page = pagination.current;
            self.fetch();
            History.push(`?page=${pagination.current}`);
        };

        const clearDetails = () => {
            self.details.logoStatus = null;
            self.details.offerMessageStatus = null;
        };

        const changeLogoStatus = (status) => {
            self.details.logoStatus = status;
        };

        const changeOfferMessageStatus = (status) => {
            self.details.offerMessageStatus = status;
        };

        const fetch = flow(function* fetch() {
            self.loaders.page = true;
            try {
                const { meta, list } = yield Request.get(adminAPI.creativeSettings.pendingReview, {
                    page: self.page,
                    search: self.search
                });
                self.list = list;
                self.meta.setMeta(meta);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders.page = false;
            }
        });

        const proceed = flow(function* proceed(_id, onClose) {
            self.loaders.details = true;
            try {
                yield Request.put(adminAPI.creativeSettings.put(_id), {
                    logoStatus: self.details.logoStatus,
                    offerMessageStatus: self.details.offerMessageStatus
                });
                onClose(true);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.loaders.details = false;
            }
        });

        return {
            changeAccount,
            checkLogoStatus,
            checkOfferMessageStatus,
            onSearchChange,
            handleTableChange,
            changePage,
            clearDetails,
            changeLogoStatus,
            changeOfferMessageStatus,
            fetch,
            proceed
        };
    });

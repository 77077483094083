import { createBrowserHistory } from 'history';

class History {
    constructor() {
        this.instance = createBrowserHistory();
    }

    push(url) {
        this.instance.push(url);
    }
}

export default new History();

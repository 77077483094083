import React from 'react';
import nextId from 'react-id-generator';
import { Redirect, Route } from 'react-router-dom';
import { brands, brandsV2 } from './pages/brands';
import { kcp } from './pages/kcp';
import { notifications } from './pages/notifications';
import { system } from './pages/system';

export const menus = {
    'brands-v2': brandsV2,
    brands,
    kcp,
    notifications,
    system
};

const filterMenus = (menus, accesses) => {
    const fullAccess = accesses.includes('*');
    const filteredMenus = [];
    menus.forEach((menu) => {
        if (menu.sublinks) {
            const sublinks = filterMenus(menu.sublinks, accesses);
            if (sublinks.length) {
                filteredMenus.push({ ...menu, sublinks });
            }
        } else if (fullAccess || accesses.includes(menu.key) || menu.divider) {
            filteredMenus.push(menu);
        }
    });
    return filteredMenus;
};

export const getMenusWithAccess = (menusKey, match, accesses) => {
    const menu = menus[menusKey];

    if (!menu) return [];

    return filterMenus(menu(match), accesses);
};

export const checkMainMenuAccess = (menusKey, accesses) => {
    const result = getMenusWithAccess(menusKey, { path: '' }, accesses);
    return result.some((menu) => !menu.divider);
};

const flattenSublinkMenus = (menus) => {
    const result = [];
    menus.forEach((menu) => {
        if (menu.sublinks) {
            result.push(...flattenSublinkMenus(menu.sublinks));
        } else {
            result.push(menu);
        }
    });
    return result;
};

export const getAccessibleMenusAndRoutes = ({ menusKey, match, accesses, routesMapper }) => {
    const menus = getMenusWithAccess(menusKey, match, accesses);
    const flattenMenus = flattenSublinkMenus(menus);

    const routes = routesMapper
        .map((route) => {
            const type = route.type || 'route';
            const routePath = route.route || route.key;
            const routeMenu = flattenMenus.find((menu) => menu.key === route.key);
            if (!routeMenu && route.key !== false) {
                return false;
            }

            if (type === 'route') {
                return (
                    <Route
                        key={`${route.key}-${nextId()}`}
                        path={`${match.path}/${routePath}`}
                        component={route.component}
                        {...(route.props || {})}
                    />
                );
            }

            if (type === 'redirect') {
                return (
                    <Redirect
                        key={`${route.key}-${nextId()}`}
                        from={`${match.path}/${routePath}`}
                        to={`${match.path}/${route.to}`}
                        {...(route.props || {})}
                    />
                );
            }

            return false;
        })
        .filter(Boolean);

    return { menus, routes };
};

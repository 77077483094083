import axios from 'axios';
import * as get from 'lodash/get';
import qs from 'qs';
import History from './History';

const axiosInstance = axios.create({
    withCredentials: true
});

class Request {
    async get(urlReq, queryObject = null) {
        const params = queryObject ? qs.stringify(queryObject) : null;
        const url = `${urlReq}${params ? '?' + params : ''}`;

        try {
            const res = await axiosInstance.get(url);
            return res.data;
        } catch (error) {
            return this.checkErrorStatus(error);
        }
    }

    async post(urlReq, data) {
        const url = `${urlReq}`;

        try {
            const res = await axiosInstance.post(url, data);
            return res.data;
        } catch (error) {
            return this.checkErrorStatus(error);
        }
    }

    async put(urlReq, data) {
        const url = `${urlReq}`;

        try {
            const res = await axiosInstance.put(url, data);
            return res.data;
        } catch (error) {
            return this.checkErrorStatus(error);
        }
    }

    async patch(urlReq, data) {
        const url = `${urlReq}`;

        try {
            const res = await axiosInstance.patch(url, data);
            return res.data;
        } catch (error) {
            return this.checkErrorStatus(error);
        }
    }

    async delete(urlReq, id) {
        const url = `${urlReq}${id ? `/${id}` : ''}`;

        try {
            const res = await axiosInstance.delete(url);
            return res.data;
        } catch (error) {
            return this.checkErrorStatus(error);
        }
    }

    checkErrorStatus(error) {
        if (error.response && error.response.status) {
            if (error.response.status === 401) {
                History.push('/sign-in');
            }
        }

        const errorMessage = get(error, 'response.data.message', error.message);
        throw new Error(errorMessage);
    }
}

const instance = new Request();
export default instance;

import { message } from 'antd';
import dayjs from 'dayjs';
import * as isEmpty from 'lodash/isEmpty';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import { EVENT_SHEET, XLSX_EXPORT_COLUMNS } from '../pages/Brands/Orders/History/constants';
import History from '../utils/History';
import Request from '../utils/Request';
import { meta } from './common';

const dateFormat = 'YYYY-MM-DD';

export const OrderHistoryStore = types
    .model('OrderHistoryStore', {
        limit: 50,
        isLoading: true,
        isModalLoading: false,
        isModalVisible: false,
        isSubmitModalVisible: false,
        isShowSelected: false,
        meta,
        page: 1,
        brand: types.maybeNull(types.string),
        search: types.optional(types.maybeNull(types.string), null),
        dateRange: types.optional(
            types.model({
                start: types.string,
                end: types.string
            }),
            {
                start: dayjs().subtract(30, 'days').format(dateFormat),
                end: dayjs().format(dateFormat)
            }
        ),
        sort: types.optional(
            types.maybeNull(
                types.model({
                    createdAt: types.frozen()
                })
            ),
            null
        ),
        filters: types.optional(
            types.maybeNull(
                types.model({
                    orderStatuses: types.frozen(),
                    klicklyStatus: types.frozen()
                })
            ),
            null
        ),
        orders: types.optional(types.array(types.frozen()), []),
        selectedRowKeys: types.optional(types.array(types.frozen()), []),
        brandsList: types.optional(types.array(types.frozen()), []),
        selectedOrderId: types.optional(types.frozen(), null)
    })
    .views((self) => ({}))
    .actions((self) => {
        const setRequestProperty = (field, value) => {
            if (typeof self[field] !== 'undefined') {
                self[field] = value;
            }
        };

        const setSelectedOrder = (selectedOrderId) => {
            self.selectedOrderId = selectedOrderId;
        };

        const openOrderDetails = (orderId) => {
            self.selectedOrderId = orderId;
            self.pushToHistory(orderId);
        };

        const openOrderInsights = (orderId) => {
            self.selectedOrderId = orderId;
            self.pushToHistory(orderId, true);
        };

        const onSearchChange = (value) => {
            self.search = value;
            self.page = 1;
            self.pushToHistory();
        };

        const onDateChange = (range) => {
            changeDateRange(range);
            self.page = 1;
            self.pushToHistory();
        };

        const changeDateRange = (range) => {
            self.dateRange.start = range[0];
            self.dateRange.end = range[1];
        };

        const pushToHistory = (orderId, insights) => {
            const searchParams = new URLSearchParams();

            searchParams.set('page', self.page.toString());

            if (self.brand) {
                searchParams.set('brand', self.brand.toString());
            }

            if (self.isShowSelected && self.selectedRowKeys.length) {
                searchParams.set('selected', self.isShowSelected);
            }

            if (self.search) {
                searchParams.set('search', encodeURIComponent(self.search.toString()));
            }

            if (!isEmpty(self.sort)) {
                searchParams.set('sort', JSON.stringify(self.sort));
            }

            if (!isEmpty(self.filters)) {
                searchParams.set('filters', JSON.stringify(self.filters));
            }

            if (self.dateRange.start) {
                searchParams.set('dateRange.start', self.dateRange.start.toString());
            }

            if (self.dateRange.end) {
                searchParams.set('dateRange.end', self.dateRange.end.toString());
            }

            if (orderId && insights) {
                return History.push(`/brands/orders/history-list/${orderId}/insights/?${searchParams.toString()}`);
            }

            if (orderId) {
                return History.push(`/brands/orders/history-list/${orderId}/?${searchParams.toString()}`);
            }

            History.push(`/brands/orders/history-list/?${searchParams.toString()}`);
        };

        const handleTableChange = (pagination, filters, sorter) => {
            if (filters && filters.orderStatus?.length) {
                self.filters.orderStatuses = filters.orderStatus;
            } else {
                self.filters.orderStatuses = [];
            }
            if (filters && filters.klicklyStatus?.length) {
                self.filters.klicklyStatus = filters.klicklyStatus;
            } else {
                self.filters.klicklyStatus = [];
            }

            if (!filters) {
                self.filters = {};
            }

            if (sorter && sorter.order) {
                self.sort[sorter.field] = sorter.order === 'ascend' ? 1 : -1;
            } else {
                self.sort = {};
            }
            self.page = pagination.current || 1;
            self.pushToHistory();
        };

        const handleOnClose = (refetch = false) => {
            self.selectedOrderId = null;
            self.pushToHistory();
            if (refetch) {
                self.fetch();
            }
        };

        const handleModalVisibility = (status) => {
            self.isModalVisible = status;
        };

        const handleSubmitModalVisibility = (status) => {
            self.isSubmitModalVisible = status;
        };

        const onExportXLSX = flow(function* fetch(values) {
            self.isModalLoading = true;
            const columns = XLSX_EXPORT_COLUMNS.reduce(
                (acc, item) => {
                    Object.keys(values).map((child) => {
                        if (item.key === child) {
                            const key = item.key === EVENT_SHEET ? 1 : 0;
                            const selectedItems = item.children.filter((obj) => values[child].includes(obj.value));
                            acc[key].children = acc[key].children.concat(selectedItems);
                        }
                    });
                    return acc;
                },
                [
                    { title: 'Orders General Information', children: [] },
                    { title: 'Customer Events History', children: [] }
                ]
            );

            yield Request.post(adminAPI.orders.history.ordersXLSX, {
                ids: self.selectedRowKeys,
                fields: columns
            });

            self.isModalLoading = false;
            self.isSubmitModalVisible = true;
            self.isModalVisible = false;
        });

        const fetch = flow(function* fetch() {
            if (!self.selectedRowKeys.length) {
                self.isShowSelected = false;
            }

            self.isLoading = true;
            try {
                const reqObject = {
                    page: self.page,
                    limit: self.limit,
                    brand: self.brand,
                    dateRange: {}
                };

                if (self.search) {
                    reqObject.search = encodeURIComponent(self.search);
                }

                if (!isEmpty(self.sort)) {
                    reqObject.sort = self.sort;
                }

                if (!isEmpty(self.filters)) {
                    reqObject.orderStatuses = self.filters.orderStatuses || [];
                    reqObject.klicklyStatus = self.filters.klicklyStatus || [];
                }

                if (self.dateRange.start) {
                    reqObject.dateRange.start = dayjs(self.dateRange.start).startOf('day').toDate();
                }

                if (self.dateRange.end) {
                    reqObject.dateRange.end = dayjs(self.dateRange.end).endOf('day').toDate();
                }

                if (self.isShowSelected && self.selectedRowKeys.length) {
                    reqObject.isShowSelected = true;
                    reqObject.selectedRows = self.selectedRowKeys;
                    reqObject.page = 1;
                    reqObject.dateRange = {
                        start: dayjs().subtract(30, 'days').format(dateFormat),
                        end: dayjs().format(dateFormat)
                    };
                    reqObject.brand = '';
                    reqObject.search = '';
                }

                const data = yield Request.get(adminAPI.orders.history.list, reqObject);
                self.orders = data.list;
                self.meta = data.meta;
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const onBrandChange = (value) => {
            self.brand = value;
            self.pushToHistory();
        };

        const onSelectRow = (record, selected) => {
            if (selected) {
                self.selectedRowKeys = [...self.selectedRowKeys, record._id];
            } else {
                self.selectedRowKeys = self.selectedRowKeys.filter((item) => item !== record._id);
            }
        };

        const onSelectAllRows = (selected, selectedRows, changeRows) => {
            let mergedRows = changeRows.map((item) => item._id);

            if (changeRows.length < 50) {
                mergedRows = [...self.selectedRowKeys, ...changeRows.map((item) => item._id)];
            }

            if (selected) {
                self.selectedRowKeys = [...new Set(mergedRows)].slice(0, 50);
            } else {
                self.selectedRowKeys = [];
            }
        };

        const onClearSelectedRows = () => {
            self.selectedRowKeys = [];
        };

        const onShowSelected = () => {
            self.isShowSelected = !self.isShowSelected;
            self.pushToHistory();
        };

        const afterCreate = flow(function* afterCreate() {
            self.isShowSelected = false;
            try {
                const response = yield Request.get(adminAPI.account.list, { limit: 0 });

                self.brandsList = response.data?.map((item) => {
                    return { label: item.name, value: item._id };
                });
            } catch (error) {
                message.error(error.message);
            }
        });

        return {
            onShowSelected,
            onSelectAllRows,
            onClearSelectedRows,
            onSelectRow,
            onBrandChange,
            afterCreate,
            handleSubmitModalVisibility,
            onExportXLSX,
            handleModalVisibility,
            setRequestProperty,
            setSelectedOrder,
            openOrderDetails,
            openOrderInsights,
            onSearchChange,
            pushToHistory,
            handleTableChange,
            handleOnClose,
            onDateChange,
            changeDateRange,
            fetch
        };
    });

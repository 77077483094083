import React from 'react';
import * as Icons from '@ant-design/icons';

export const kcp = (match) => [
    {
        link: `${match.path}/accounts`,
        icon: <Icons.ReconciliationOutlined />,
        text: 'Accounts',
        key: 'kcp-accounts',
        menuTab: 'kcp-accounts'
    },
    {
        link: `${match.path}/orders/pending-review`,
        icon: <Icons.ClockCircleOutlined />,
        text: 'Pending orders',
        key: 'orders-pending-review',
        menuTab: 'orders-pending-review'
    },
    {
        link: `${match.path}/reviews`,
        icon: <Icons.EditOutlined />,
        text: 'Reviews',
        key: 'reviews',
        menuTab: 'reviews'
    },
    {
        link: `${match.path}/rewards`,
        icon: <Icons.CreditCardOutlined />,
        text: 'Rewards',
        key: 'rewards',
        menuTab: 'rewards'
    },
    {
        link: `${match.path}/upsells`,
        icon: <Icons.PlusSquareOutlined />,
        text: 'Upsells',
        key: 'upsells',
        menuTab: 'upsells'
    },
    {
        link: `${match.path}/giftly`,
        icon: <Icons.GiftOutlined />,
        text: 'Giftly',
        key: 'giftly',
        menuTab: 'giftly',
        sublinks: [
            {
                link: `${match.path}/giftly/customers`,
                icon: <Icons.UserOutlined />,
                text: 'Customers',
                key: 'giftly-customers',
                menuTab: 'giftly-customers'
            },
            {
                link: `${match.path}/giftly/gs-products`,
                icon: <Icons.GiftOutlined />,
                text: 'GS Products',
                key: 'giftly-gs-products',
                menuTab: 'giftly-gs-products'
            },
            {
                link: `${match.path}/giftly/gs-explorer`,
                icon: <Icons.CoffeeOutlined />,
                text: 'GS Explorer',
                key: 'giftly-gs-explorer',
                menuTab: 'giftly-gs-explorer'
            },
            {
                link: `${match.path}/giftly/invite-codes`,
                icon: <Icons.CodeOutlined />,
                text: 'Invite codes',
                key: 'giftly-invite-codes',
                menuTab: 'giftly-invite-codes'
            },
            {
                link: `${match.path}/giftly/tasks`,
                icon: <Icons.ThunderboltOutlined />,
                text: 'Tasks',
                key: 'giftly-tasks',
                menuTab: 'giftly-tasks'
            }
        ]
    },
    {
        link: `${match.path}/advertise`,
        icon: <Icons.FontColorsOutlined />,
        text: 'Advertise',
        key: 'advertise',
        menuTab: 'advertise',
        sublinks: [
            {
                link: `${match.path}/advertise/generators`,
                icon: <Icons.CalculatorOutlined />,
                text: 'Generators',
                key: 'advertise-generators',
                menuTab: 'advertise-generators'
            },
            {
                link: `${match.path}/advertise/strategies`,
                icon: <Icons.BranchesOutlined />,
                text: 'Strategies',
                key: 'advertise-strategies',
                menuTab: 'advertise-strategies'
            }
        ]
    },
    {
        link: `${match.path}/publishers`,
        icon: <Icons.BankOutlined />,
        text: 'Publishers',
        key: 'publishers',
        menuTab: 'publishers'
    }
];

import React from 'react';
import * as Icons from '@ant-design/icons';
import { adminAPI } from '../../api';

export const system = (match) => [
    {
        link: `${match.path}/admin-users`,
        icon: <Icons.TeamOutlined />,
        text: 'Admin users',
        key: 'admin-users',
        menuTab: 'admin-users'
    },
    {
        link: `${match.path}/script-tags`,
        icon: <Icons.ShopOutlined />,
        text: 'Script tags',
        key: 'script-tags',
        menuTab: 'script-tags'
    },
    {
        link: `${match.path}/web-pixels`,
        icon: <Icons.ShoppingOutlined />,
        text: 'Web pixels',
        key: 'web-pixels',
        menuTab: 'web-pixels'
    },
    {
        link: `${match.path}/elastic-migrations`,
        icon: <Icons.RetweetOutlined />,
        text: 'ES migrations',
        key: 'elastic-migrations',
        menuTab: 'elastic-migrations'
    },
    { divider: true },
    {
        icon: <Icons.MonitorOutlined />,
        text: 'Workers monitor',
        key: 'workers-monitor',
        menuTab: 'workers-monitor',
        href: adminAPI.workers.monitor,
        target: '_blank'
    },
    {
        icon: <Icons.SyncOutlined />,
        text: 'Cache sync',
        key: 'cache',
        menuTab: 'cache',
        link: `${match.path}/cache`
    }
];

import React, { Fragment, useEffect } from 'react';
import { GoogleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { inject } from 'mobx-react';
import qs from 'qs';
import History from '../../../../../utils/History';

export const SignInGoogle = inject('store')(({ store }) => {
    useEffect(() => {
        const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (queryParams.code) {
            store.user.googleProcessCode(queryParams.code);
        }
    }, []); // eslint-disable-line

    return (
        <Fragment>
            <Button
                onClick={store.user.googleRedirectUrl}
                size='large'
                icon={<GoogleOutlined />}
                type='primary'
                htmlType='submit'
                className='login-form-button'
            >
                Sign in with Klickly Google account
            </Button>

            {/* eslint-disable-next-line */}
            <div className='sign-in-manual-text' onClick={() => History.push('/sign-in/manual')}>
                Manual sign-in (using email and password)
            </div>
        </Fragment>
    );
});

const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;
const KCP_API_URL = process.env.REACT_APP_KCP_API_URL;
export const GIFTLY_URL = process.env.REACT_APP_GIFTLY_URL;
export const BOA_DASHBOARD_URL = process.env.REACT_APP_BOA_DASHBOARD_URL;

const makeAdminURL = (pathname) => {
    return `${ADMIN_API_URL}${pathname}`;
};

const makeAdminURLV2 = (pathname) => {
    return `${ADMIN_API_URL}/v2${pathname}`;
};

const makeKcpURL = (pathname) => {
    return `${KCP_API_URL}${pathname}`;
};

export const adminAPI = {
    account: {
        list: makeAdminURL('/accounts'),
        get: (id) => makeAdminURL(`/accounts/${id}`),
        put: (id) => makeAdminURL(`/accounts/${id}`),
        terminate: (id) => makeAdminURL(`/accounts/${id}/terminate`),
        loggedInAs: (userId) => makeAdminURL(`/accounts/loggedInAs/${userId}`),
        blockedEmails: {
            actionUrl: (accountId) => makeAdminURL(`/account-block-emails/${accountId}`),
            files: (accountId) => makeAdminURL(`/account-block-emails/${accountId}/files`)
        },
        blockedProducts: {
            actionUrl: (accountId) => makeAdminURL(`/account-block-products/${accountId}`)
        },
        scriptCheckTrigger: makeAdminURL('/accounts/script-check-trigger'),
        updateScriptTags: makeAdminURL('/accounts/update-shopify-tracker'),
        cacheSync: makeAdminURL('/accounts/cache-sync'),
        statusStats: makeAdminURL('/accounts/status-stats'),
        users: {
            remove: (accountId) => makeAdminURL(`/accounts/${accountId}/users`)
        },
        invoices: {
            list: (id) => makeAdminURL(`/account-invoices/list/${id}`),
            process: (id) => makeAdminURL(`/account-invoices/process/${id}`),
            split: (id) => makeAdminURL(`/account-invoices/split/${id}`),
            changeStatus: (id) => makeAdminURL(`/account-invoices/change-status/${id}`),
            refund: makeAdminURL('/account-invoices/refund')
        },
        payouts: {
            list: (id) => makeAdminURL(`/account-payouts/list/${id}`),
            process: (id) => makeAdminURL(`/account-payouts/process/${id}`),
            markPaid: (id) => makeAdminURL(`/account-payouts/mark-paid/${id}`)
        },
        payments: {
            get: (id) => makeAdminURL(`/accounts/${id}/payments`),
            put: (id) => makeAdminURL(`/accounts/${id}/payments`)
        }
    },
    accountPromotions: {
        review: {
            list: makeAdminURL('/review/list'),
            accountList: (accountId) => makeAdminURL(`/review/list/${accountId}`),
            bulk: (id) => makeAdminURL(`/review/bulk/${id}`),
            get: (promotionId) => makeAdminURL(`/review/promotion/${promotionId}`),
            changeStatus: (promotionId) => makeAdminURL(`/review/promotion/${promotionId}/status`)
        }
    },
    creativeSettings: {
        pendingReview: makeAdminURL('/creative-settings/pending-review'),
        put: (id) => makeAdminURL(`/creative-settings/${id}`)
    },
    adminUser: {
        me: makeAdminURL('/admin-users/me'),
        mfa: {
            setup: makeAdminURL('/admin-users/mfa-setup'),
            verify: makeAdminURL('/admin-users/mfa-verify'),
            ipWhitelist: makeAdminURL('/admin-users/ip-whitelist')
        }
    },
    auth: {
        signIn: makeAdminURL('/auth'),
        logout: makeAdminURL('/auth/logout'),
        google: {
            redirectUrl: makeAdminURL('/auth/google'),
            callback: makeAdminURL('/auth/google')
        }
    },
    system: {
        users: makeAdminURL('/admin-users'),
        ipWhitelist: makeAdminURL('/ip-whitelist'),
        esMigrations: makeAdminURL('/system/es-migrations')
    },
    kcp: {
        account: {
            list: makeAdminURL('/kcp/accounts'),
            get: (id) => makeAdminURL(`/kcp/accounts/${id}`),
            put: (id) => makeAdminURL(`/kcp/accounts/${id}`),
            shippingRates: (id) => makeAdminURL(`/kcp/accounts/${id}/shipping-rates`)
        },
        csp: {
            list: makeAdminURL('/kcp/csp'),
            refreshCache: makeAdminURL('/kcp/csp/refresh-cache'),
            updateBrandShipping: (id) => makeAdminURL(`/kcp/csp/update-shipping/${id}`),
            recalculateKlicklyPrice: makeAdminURL('/kcp/csp/recalculate-klickly-price'),
            explorer: makeAdminURL('/kcp/csp/explorer'),
            addRequest: (id) => makeAdminURL(`/kcp/csp/add-request/${id}`),
            get: (id) => makeAdminURL(`/kcp/csp/${id}`),
            create: makeAdminURL('/kcp/csp'),
            update: (id) => makeAdminURL(`/kcp/csp/${id}`),
            remove: (id) => makeAdminURL(`/kcp/csp/${id}`)
        },
        customers: {
            fetchUrl: makeAdminURL('/kcp/customers'),
            patch: (id) => makeAdminURL(`/kcp/customers/${id}`),
            remove: (id) => makeAdminURL(`/kcp/customers/${id}`),
            block: (id) => makeAdminURL(`/kcp/customers/${id}/block`),
            blockReason: (id) => makeAdminURL(`/kcp/customers/${id}/block-reason`),
            unblock: (id) => makeAdminURL(`/kcp/customers/${id}/unblock`),
            approve: (id) => makeAdminURL(`/kcp/customers/${id}/approve`),
            changeInviteTaskAttempts: (id) => makeAdminURL(`/kcp/customers/${id}/change-invite-task-attempts`)
        },
        inviteCodes: {
            fetchUrl: makeAdminURL('/kcp/invite-codes')
        },
        tasks: {
            fetchUrl: makeAdminURL('/kcp/tasks'),
            statistic: makeAdminURL('/kcp/tasks/statistics')
        },
        reviews: {
            accounts: makeAdminURL('/kcp/reviews/accounts'),
            list: makeAdminURL('/kcp/reviews'),
            get: (id) => makeAdminURL(`/kcp/reviews/${id}`),
            patch: (id) => makeAdminURL(`/kcp/reviews/${id}`)
        },
        rewards: makeAdminURL('/kcp/rewards'),
        upsells: {
            fetchUrl: makeAdminURL('/kcp/upsells')
        },
        dataPrivacy: {
            list: makeAdminURL('/kcp/data-privacy/requests'),
            submit: makeAdminURL('/kcp/data-privacy/requests/submit')
        }
    },
    publishers: {
        fetchUrl: makeAdminURL('/publishers')
    },
    orders: {
        get: (id) => makeAdminURL(`/orders/get/${id}`),
        refund: (id) => makeAdminURL(`/orders/refund/${id}`),
        history: {
            list: makeAdminURL('/orders/history/list'),
            ordersXLSX: makeAdminURL('/orders/history/orders-xlsx')
        },
        insights: (id) => makeAdminURL(`/orders/get/${id}/insights`),
        extend: (id) => makeAdminURL(`/orders/get/${id}/insights/extend`),
        pendingReview: {
            list: makeAdminURL('/orders-pending-review/list'),
            accountList: (accountId) => makeAdminURL(`/orders-pending-review/list/${accountId}`),
            proceed: (id) => makeAdminURL(`/orders-pending-review/proceed/${id}`),
            refund: (id) => makeAdminURL(`/orders-pending-review/refund/${id}`)
        },
        trackingNumber: makeAdminURL('/orders/tracking-number')
    },
    notifications: {
        condition: {
            fetchUrl: makeAdminURL('/notifications/condition'),
            constants: makeAdminURL('/notifications/condition/get/constants'),
            textData: (id) => makeAdminURL(`/notifications/condition/${id}/text-data`),
            selectList: makeAdminURL('/notifications/condition/get/select-list'),
            audience: makeAdminURL('/notifications/condition/get/audience'),
            audienceCSV: makeAdminURL('/notifications/condition/get/audience-csv')
        },
        mailingList: {
            fetchUrl: makeAdminURL('/notifications/mailing-list'),
            audienceList: (id) => makeAdminURL(`/notifications/mailing-list/${id}/audience-list`),
            selectList: makeAdminURL('/notifications/mailing-list/get/select-list'),
            fileUpload: makeAdminURL('/notifications/mailing-list/file/upload')
        },
        template: {
            fetchUrl: makeAdminURL('/notifications/template'),
            selectList: makeAdminURL('/notifications/template/get/select-list'),
            preview: (id) => makeAdminURL(`/notifications/template/${id}/preview`),
            testIt: (id) => makeAdminURL(`/notifications/template/${id}/test-it`)
        },
        campaign: {
            fetchUrl: makeAdminURL('/notifications/campaign'),
            launch: (id) => makeAdminURL(`/notifications/campaign/${id}/launch`),
            stop: (id) => makeAdminURL(`/notifications/campaign/${id}/stop`)
        },
        automation: {
            fetchUrl: makeAdminURL('/notifications/automation')
        },
        serviceMail: {
            fetchUrl: makeAdminURL('/notifications/service-mail')
        },
        trigger: {
            fetchUrl: makeAdminURL('/notifications/trigger'),
            selectList: makeAdminURL('/notifications/trigger/get/select-list'),
            testIt: (id) => makeAdminURL(`/notifications/trigger/${id}/test-it`)
        },
        banList: {
            fetchUrl: makeAdminURL('/notifications/ban-list'),
            fileUpload: makeAdminURL('/notifications/ban-list/file/upload')
        },
        monitor: makeAdminURL('/notifications/monitor')
    },
    advertise: {
        generators: makeAdminURL('/advertise-generators'),
        strategies: makeAdminURL('/advertise-strategies')
    },
    reports: {
        generate: makeAdminURL('/reports')
    },
    customerPrivacy: {
        sendRequest: makeAdminURL('/customer-privacy'),
        optOut: makeAdminURL('/customer-privacy/opt-out')
    },
    lambda: {
        performanceJobs: makeAdminURL('/lambda/sls-cron/performance-jobs')
    },
    workers: {
        monitor: makeAdminURL('/workers/monitor')
    }
};

export const kcpAPI = {
    marketplace: {
        search: {
            basic: makeKcpURL('/marketplace/search')
        }
    }
};

export const adminAPIV2 = {
    account: {
        updateScriptTags: makeAdminURLV2('/accounts/update-script-tags'),
        updateWebPixel: makeAdminURLV2('/accounts/update-web-pixel'),
        invoiceRecalculate: (id) => makeAdminURLV2(`/accounts/${id}/invoices/recalculate`)
    }
};

import { message } from 'antd';
import * as each from 'lodash/each';
import * as findIndex from 'lodash/findIndex';
import * as get from 'lodash/get';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

const { REACT_APP_DASHBOARD_URL } = process.env;

export const AccountStore = types
    .model('AccountStore', {
        isLoading: false,
        isLoadingInvoices: false,
        isLoadingPayouts: false,
        isPendingPayout: false,
        isTerminating: false,
        object: types.frozen(),
        invoices: types.optional(
            types.array(
                types.model({
                    _id: types.string,
                    account: types.string,
                    refunded: types.number,
                    invoiceNumber: types.string,
                    status: types.string,
                    chargeAttempts: types.number,
                    typeOfPayment: types.string,
                    offsetDetails: types.frozen(),
                    updatedAt: types.string,
                    createdAt: types.string,
                    items: types.optional(
                        types.array(
                            types.model({
                                _id: types.string,
                                account: types.string,
                                invoice: types.string,
                                order: types.optional(types.string, ''),
                                itemNumber: types.string,
                                orderNumber: types.optional(types.string, ''),
                                campaigns: types.optional(types.array(types.frozen()), []),
                                amount: types.number,
                                refunded: types.number,
                                type: types.string,
                                externalOrderId: types.optional(types.string, ''),
                                info: types.frozen(),
                                updatedAt: types.frozen(),
                                createdAt: types.frozen()
                            })
                        ),
                        []
                    ),
                    refundDetails: types.optional(
                        types.array(types.model({ campaignId: types.string, refunded: types.number })),
                        []
                    ),
                    amount: types.number
                })
            ),
            []
        ),
        payouts: types.optional(types.array(types.frozen()), []),
        paymentData: types.frozen()
    })
    .views((self) => ({
        getValue(field, defaultValue = '') {
            return get(self.object, field, defaultValue);
        },
        setInvoiceStatus(invoiceId, status) {
            const invoiceIndex = findIndex(self.invoices, { _id: invoiceId });
            if (invoiceIndex !== -1) {
                self.invoices[invoiceIndex].status = status;
            }
        }
    }))
    .actions((self) => {
        const clear = () => {
            self.object = {};
            self.invoices = [];
            self.payouts = [];
        };

        const toggleInvoiceBlockLoading = (status) => {
            self.isLoadingInvoices = status;
        };

        const togglePayoutBlockLoading = (status) => {
            self.isLoadingPayouts = status;
        };

        /**
         * ControlSettings status setter
         */
        const onControlListStatusChange = (index, status) => {
            self.object.controlSettings.list[index].status = status;
        };

        const updateControlSettings = () => {
            return Request.put(adminAPI.account.put(self.object._id), {
                controlSettings: self.object.controlSettings
            });
        };

        const fetch = flow(function* fetch(_id) {
            try {
                self.isLoading = true;
                self.object = yield Request.get(adminAPI.account.get(_id));
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const scriptCheckTrigger = flow(function* scriptCheckTrigger(_id) {
            self.isLoading = true;
            try {
                yield Request.post(adminAPI.account.scriptCheckTrigger, { accountId: self.getValue('_id') });
                message.success('Account script check sent to queue, please refresh page in 2-3 minutes');
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const deleteAccountUser = flow(function* deleteAccountUser(userId) {
            self.isLoading = true;

            try {
                yield Request.delete(adminAPI.account.users.remove(self.getValue('_id')), userId);
                yield self.fetch(self.getValue('_id'));
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const loggedInAs = flow(function* loggedInAs(userLinkId) {
            self.isLoading = true;

            try {
                yield Request.post(adminAPI.account.loggedInAs(userLinkId));
                window.open(`${REACT_APP_DASHBOARD_URL}/${self.getValue('shortId')}/dashboard`, '_blank');
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const update = flow(function* update(values, successMessage = null) {
            self.isLoading = true;

            try {
                yield Request.put(adminAPI.account.put(self.object._id), values);

                self.object = {
                    ...self.object,
                    ...values
                };

                if (successMessage) {
                    message.success(successMessage);
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const loadPayouts = flow(function* loadPayouts(search = {}) {
            self.payouts = [];

            if (!(self.object && self.object._id)) {
                return false;
            }

            try {
                self.togglePayoutBlockLoading(true);
                self.payouts = yield Request.get(adminAPI.account.payouts.list(self.object._id), { search });
            } catch (error) {
                message.error(error.message);
            } finally {
                self.togglePayoutBlockLoading(false);
            }
        });

        const loadInvoices = flow(function* loadInvoices(search = {}) {
            self.invoices = [];

            if (!(self.object && self.object._id)) {
                return false;
            }

            try {
                self.toggleInvoiceBlockLoading(true);
                self.invoices = yield Request.get(adminAPI.account.invoices.list(self.object._id), { search });
            } catch (error) {
                message.error(error.message);
            } finally {
                self.toggleInvoiceBlockLoading(false);
            }
        });

        const updateInvoiceItemRefund = (invoiceId, itemId = null, refundAmount = 0) => {
            const invoiceIndex = findIndex(self.invoices, { _id: invoiceId });
            if (invoiceIndex !== -1) {
                if (itemId !== null) {
                    const invoiceItemIndex = findIndex(self.invoices[invoiceIndex].items, { _id: itemId });
                    if (invoiceItemIndex !== -1) {
                        self.invoices[invoiceIndex].items[invoiceItemIndex].refunded = refundAmount;
                    }
                    self.invoices[invoiceIndex].refunded += refundAmount;
                } else {
                    self.invoices[invoiceIndex].refunded = self.invoices[invoiceIndex].amount;
                    self.invoices[invoiceIndex].status = 'refunded';
                    each(self.invoices[invoiceIndex].items, (item) => {
                        item.refunded = item.amount;
                    });
                }
            }
        };

        const processInvoice = flow(function* processInvoice(invoice) {
            if (!(self.object && self.object._id && invoice && invoice._id)) {
                return false;
            }

            try {
                self.toggleInvoiceBlockLoading(true);
                yield Request.post(adminAPI.account.invoices.process(invoice._id));
                self.setInvoiceStatus(invoice._id, 'processing');
            } catch (error) {
                message.error(error.message);
            } finally {
                self.toggleInvoiceBlockLoading(false);
            }
        });

        const splitInvoice = flow(function* splitInvoice(invoiceId, items) {
            try {
                self.toggleInvoiceBlockLoading(true);
                yield Request.post(adminAPI.account.invoices.split(invoiceId), { items });
                self.loadInvoices();
            } catch (error) {
                message.error(error.message);
            } finally {
                self.toggleInvoiceBlockLoading(false);
            }
        });

        const changeInvoiceStatus = flow(function* changeInvoiceStatus(invoice, status) {
            if (!(self.object && self.object._id && invoice && invoice._id)) {
                return false;
            }

            try {
                self.toggleInvoiceBlockLoading(true);
                yield Request.post(adminAPI.account.invoices.changeStatus(invoice._id), { status });
                self.setInvoiceStatus(invoice._id, status);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.toggleInvoiceBlockLoading(false);
            }
        });

        const processPayout = flow(function* processPayout(payout) {
            if (!(self.object && self.object._id && payout && payout._id)) {
                return false;
            }

            try {
                self.togglePayoutBlockLoading(true);
                yield Request.post(adminAPI.account.payouts.process(payout._id), {
                    accountId: payout.account
                });
                payout.status = 'processing';
            } catch (error) {
                message.error(error.message);
            } finally {
                self.togglePayoutBlockLoading(false);
            }
        });

        const refundAllInvoice = flow(function* refundAllInvoice(invoice) {
            self.toggleInvoiceBlockLoading(true);
            try {
                yield Request.post(adminAPI.account.invoices.refund, {
                    invoiceId: invoice._id,
                    refundAmount: 1
                });
                message.success(`Invoice #${invoice.invoiceNumber} has been fully refunded successfully`);

                self.updateInvoiceItemRefund(invoice._id);
            } catch (error) {
                message.error(error.message);
            } finally {
                self.toggleInvoiceBlockLoading(false);
            }
        });

        const terminate = flow(function* terminate() {
            try {
                self.isTerminating = true;
                yield Request.delete(adminAPI.account.terminate(self.object._id));
                self.isTerminating = false;
            } catch (error) {
                message.error(error.message);
                self.isTerminating = false;
            }
        });

        const getAccountPayments = flow(function* getAccountPayments() {
            const accountId = self.getValue('_id');
            try {
                self.paymentData = yield Request.get(adminAPI.account.payments.get(accountId));
            } catch (error) {
                message.error(error.message);
                return false;
            }
            return true;
        });

        const updateAccountPayments = flow(function* updateAccountPayments(values) {
            const accountId = self.getValue('_id');
            try {
                self.paymentData = yield Request.put(adminAPI.account.payments.put(accountId), values);
                message.success('Settings "Payments" has been saved successfully!');
            } catch (error) {
                message.error(error.message);
                return false;
            }
            return true;
        });

        const markPayoutAsPaid = flow(function* markPayoutAsPaid(payoutId, data) {
            self.isPendingPayout = true;
            try {
                yield Request.post(adminAPI.account.payouts.markPaid(payoutId), data);
                message.success('Payout marked as paid');
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isPendingPayout = false;
            }
        });

        return {
            clear,
            toggleInvoiceBlockLoading,
            togglePayoutBlockLoading,
            onControlListStatusChange,
            updateControlSettings,
            fetch,
            scriptCheckTrigger,
            deleteAccountUser,
            loggedInAs,
            update,
            loadPayouts,
            loadInvoices,
            updateInvoiceItemRefund,
            processInvoice,
            splitInvoice,
            changeInvoiceStatus,
            processPayout,
            refundAllInvoice,
            terminate,
            getAccountPayments,
            updateAccountPayments,
            markPayoutAsPaid
        };
    });

import React from 'react';
import * as Icons from '@ant-design/icons';
import { adminAPI } from '../../api';

export const notifications = (match) => [
    {
        icon: <Icons.ApartmentOutlined />,
        text: 'Conditions',
        key: 'conditions',
        menuTab: 'conditions',
        link: `${match.path}/conditions`
    },
    {
        icon: <Icons.UsergroupAddOutlined />,
        text: 'Mailing lists',
        key: 'mailing-lists',
        menuTab: 'mailing-lists',
        link: `${match.path}/mailing-lists`
    },
    {
        icon: <Icons.StopOutlined />,
        text: 'Ban list',
        key: 'ban-list',
        menuTab: 'ban-list',
        link: `${match.path}/ban-list`
    },
    {
        icon: <Icons.LayoutOutlined />,
        text: 'Templates',
        key: 'templates',
        menuTab: 'templates',
        link: `${match.path}/templates`
    },
    {
        divider: true
    },
    {
        icon: <Icons.SnippetsOutlined />,
        text: 'Campaigns',
        key: 'campaigns',
        menuTab: 'campaigns',
        link: `${match.path}/campaigns`
    },
    {
        divider: true
    },
    {
        icon: <Icons.ApiOutlined />,
        text: 'Triggers',
        key: 'triggers',
        menuTab: 'triggers',
        link: `${match.path}/triggers`
    },
    {
        icon: <Icons.BranchesOutlined />,
        text: 'Automations',
        key: 'automations',
        menuTab: 'automations',
        link: `${match.path}/automations`
    },
    {
        icon: <Icons.SettingOutlined />,
        text: 'Service Mails',
        key: 'service-mails',
        menuTab: 'service-mails',
        link: `${match.path}/service-mails`
    },
    {
        divider: true
    },
    {
        icon: <Icons.MonitorOutlined />,
        text: 'Queues monitor',
        key: 'queues-monitor',
        menuTab: 'queues-monitor',
        href: adminAPI.notifications.monitor,
        target: '_blank'
    }
];

import { message } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import History from '../utils/History';
import Request from '../utils/Request';
import { meta } from './common';

export const OrdersReviewByAccountStore = types
    .model('OrdersReviewByAccountStore', {
        isLoading: false,
        accountId: types.maybeNull(types.frozen()),
        meta,
        currentPage: 1,
        orders: types.optional(types.array(types.frozen()), []),
        selectedOrder: types.maybeNull(types.frozen())
    })
    .views((self) => ({}))
    .actions((self) => {
        const setAccountId = (accountId) => {
            self.accountId = accountId;
        };

        const setSelectedOrder = (selectedOrder) => {
            self.selectedOrder = selectedOrder;
        };

        const handleTableChange = (pagination) => {
            self.currentPage = pagination.current;
            self.fetch();
            History.push(`?page=${pagination.current}`);
        };

        const handleOnClose = (refetch = false) => {
            self.selectedOrder = null;
            if (refetch) {
                self.fetch();
            }
        };

        const fetch = flow(function* fetch() {
            self.isLoading = true;
            try {
                const data = yield Request.get(adminAPI.orders.pendingReview.accountList(self.accountId), {
                    page: self.currentPage
                });
                self.orders = data.list;
                self.meta = data.meta;
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        return {
            setAccountId,
            setSelectedOrder,
            handleTableChange,
            handleOnClose,
            fetch
        };
    });

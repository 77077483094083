import { message } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { meta } from './common';

export const DataPrivacyStore = types
    .model('DataPrivacyStore', {
        isLoading: true,
        isSubmitting: false,
        payload: types.optional(
            types.model({
                search: types.maybeNull(types.string),
                page: types.number,
                limit: types.number
            }),
            {
                search: '',
                page: 1,
                limit: 50
            }
        ),
        selectedRowKeys: types.optional(types.array(types.string), []),
        list: types.optional(types.array(types.frozen()), []),
        meta
    })
    .views((self) => ({}))
    .actions((self) => {
        const fetch = flow(function* fetch() {
            self.isLoading = true;

            try {
                const reqObject = {
                    page: self.payload.page,
                    limit: self.payload.limit
                };

                if (self.payload.search) {
                    reqObject.search = self.payload.search;
                }

                const data = yield Request.get(adminAPI.kcp.dataPrivacy.list, reqObject);

                self.list = data.data;
                self.meta = data.meta;
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const patch = flow(function* fetch(id, body) {
            yield Request.put(`${adminAPI.kcp.dataPrivacy.list}/${id}`, body);
        });

        const onSearchChange = (value) => {
            self.payload.search = value;
            self.fetch();
        };

        const onPageChange = (page, limit) => {
            self.payload.page = page;
            self.payload.limit = limit;
            self.fetch();
        };

        const clearSelectedRowKeys = () => {
            self.selectedRowKeys = [];
        };

        const onSelectChange = (newSelectedRowKeys) => {
            if (!newSelectedRowKeys.length) {
                clearSelectedRowKeys();
            }

            self.selectedRowKeys = newSelectedRowKeys;
        };

        const getCheckboxProps = (record) => ({
            disabled: !(record.status === 'created' || record.status === 'failed')
        });

        const submit = flow(function* fetch() {
            try {
                if (!self.selectedRowKeys) {
                    return;
                }

                self.isSubmitting = true;

                yield Request.post(adminAPI.kcp.dataPrivacy.submit, {
                    ids: self.selectedRowKeys
                });

                message.success('Successfully submitted');

                clearSelectedRowKeys();

                self.fetch();
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isSubmitting = false;
            }
        });

        const del = flow(function* onItemDelete(record, onDeleteItemSuccess) {
            self.isLoading = true;

            try {
                yield Request.delete(adminAPI.kcp.dataPrivacy.list, record._id);

                if (onDeleteItemSuccess) {
                    onDeleteItemSuccess(record);
                }

                self.fetch();
            } catch (error) {
                message.error(error.message);
            }

            self.isLoading = false;
        });

        return {
            fetch,
            patch,
            del,
            submit,
            onSearchChange,
            onPageChange,
            onSelectChange,
            getCheckboxProps,
            clearSelectedRowKeys
        };
    });

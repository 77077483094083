import { message } from 'antd';
import * as find from 'lodash/find';
import * as findIndex from 'lodash/findIndex';
import * as get from 'lodash/get';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const GiftlyCustomerStore = types
    .model('GiftlyCustomerStore', {
        isLoading: false,
        isModalVisible: false,
        isCustomerBlocked: false,
        customerBlockedReason: types.optional(types.maybeNull(types.string), null),
        testMode: false,
        customer: types.optional(types.maybeNull(types.frozen()), null),
        tasks: types.array(
            types.model({
                _id: types.optional(types.maybeNull(types.string), null),
                attempts: types.optional(types.maybeNull(types.number), null),
                task: types.optional(types.maybeNull(types.frozen()), null),
                results: types.optional(types.maybeNull(types.frozen()), null)
            })
        ),
        signUpStatus: 'rejected'
    })
    .views((self) => ({
        get customerInviteTask() {
            const tasks = self.tasks || [];
            return find(tasks, (t) => {
                return get(t, 'task.alias', '') === 'invite-friend';
            });
        },
        get customerInviteTaskIndex() {
            const tasks = self.tasks || [];
            return findIndex(tasks, (t) => {
                return get(t, 'task.alias', '') === 'invite-friend';
            });
        }
    }))
    .actions((self) => {
        const setData = (data) => {
            self.isCustomerBlocked = !!data.customerBan?.customer;
            self.customerBlockedReason = data.customerBan?.comment;
            self.customer = data;
            self.tasks = data.customerTasks;
            self.signUpStatus = data.giftly.signUpStatus;
            self.testMode = data.giftly.testMode;
        };

        const getField = (field, defaultValue = '') => {
            return get(self.customer, field, defaultValue);
        };

        const blockCustomer = flow(function* (status) {
            self.isLoading = true;
            try {
                const result = yield Request.post(adminAPI.kcp.customers.block(self.getField('_id')), {
                    email: self.getField('email'),
                    ip: self.getField('giftly.clientIp')
                });
                if (result) {
                    self.isCustomerBlocked = status;
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const blockReason = flow(function* (values) {
            self.isLoading = true;
            try {
                yield Request.post(adminAPI.kcp.customers.blockReason(self.getField('_id')), {
                    comment: values.comment
                });
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const unBlockCustomer = flow(function* (status) {
            self.isLoading = true;
            try {
                const result = yield Request.post(adminAPI.kcp.customers.unblock(self.getField('_id')), {});
                if (result) {
                    self.isCustomerBlocked = status;
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const changeInviteModalVisibility = (visible) => {
            self.isModalVisible = visible;
        };

        const approveCustomer = flow(function* () {
            self.isLoading = true;
            try {
                const result = yield Request.post(adminAPI.kcp.customers.approve(self.getField('_id')), {});
                if (result) {
                    self.signUpStatus = 'approved';
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const changeCustomerInviteAttempts = flow(function* (value) {
            self.changeInviteModalVisibility(false);
            self.isLoading = true;

            try {
                const result = yield Request.post(
                    adminAPI.kcp.customers.changeInviteTaskAttempts(self.getField('_id')),
                    {
                        taskId: self.customerInviteTask._id,
                        attempts: value
                    }
                );
                if (result) {
                    self.customerInviteTask.attempts = value;
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                self.isLoading = false;
            }
        });

        const removeCustomer = flow(function* () {
            self.isLoading = false;
            try {
                yield Request.delete(adminAPI.kcp.customers.remove(self.getField('_id')));
                return true;
            } catch (error) {
                message.error(error.message);
                self.isLoading = false;
            }
            return false;
        });

        const changeTestMode = flow(function* (testMode) {
            self.isLoading = false;
            try {
                yield Request.patch(adminAPI.kcp.customers.patch(self.getField('_id')), {
                    testMode
                });
                self.testMode = testMode;
            } catch (error) {
                message.error(error.message);
            }
            self.isLoading = false;
        });

        return {
            blockReason,
            unBlockCustomer,
            blockCustomer,
            setData,
            getField,
            changeInviteModalVisibility,
            approveCustomer,
            changeCustomerInviteAttempts,
            changeTestMode,
            removeCustomer
        };
    });

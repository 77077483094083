import React from 'react';
import { Button, Divider, Space, Typography } from 'antd';
import { inject } from 'mobx-react';
import History from '../../../../utils/History';
import { AuthLayout } from '../../components/Layout';

export const PageIPWhitelist = inject('store')(({ store }) => {
    return (
        <AuthLayout>
            <Typography.Title style={{ textAlign: 'center' }} level={5}>
                New IP address detected!
            </Typography.Title>
            <Divider />
            <div>
                Looks like you have a new IP address which is not attached to your user profile. Do you want to add it
                to the list of allowed IP addresses?
            </div>
            <Divider />
            <Typography.Title style={{ textAlign: 'center' }} level={3}>
                IP: {store.user.clientIp}
            </Typography.Title>
            <Divider />
            <Space>
                <Button onClick={() => store.user.ipWhitelist()} size='large' type='primary'>
                    Trust this place, add my IP
                </Button>
                <Button onClick={() => History.push('/')} size='large'>
                    No, I don&apos;t trust this place
                </Button>
            </Space>
        </AuthLayout>
    );
});

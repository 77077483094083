import { notification } from 'antd';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';
import { isEmail } from '../utils/validators';

const REQUEST_CUSTOMER_DATA_TYPE = 'requestCustomerData';
const ERASE_CUSTOMER_DATA_TYPE = 'eraseCustomerData';
const PREVIEW_CUSTOMER_DATA_TYPE = 'previewCustomerData';

export const CustomerPrivacyDataStore = types
    .model('CustomerPrivacyDataStore', {
        email: '',
        source: '',
        brandSearch: '',
        brand: '',
        brandsList: types.optional(types.array(types.frozen()), []),
        customersToErase: ''
    })
    .views((self) => ({}))
    .actions((self) => {
        const clearData = () => {
            self.email = '';
            self.source = '';
            self.brandSearch = '';
            self.brand = '';
            self.brandsList = [];
            self.customersToErase = '';
        };

        const setEmail = (email) => {
            self.email = email;
        };

        const setSource = (source) => {
            self.source = source;
            self.brand = null;
        };

        const setBrandSearch = (brandSearch) => {
            self.brandSearch = brandSearch;
        };

        const setCustomersToErase = (customersToErase) => {
            self.customersToErase = customersToErase;

            if (customersToErase === 'all') {
                self.setEmail('');
            }
        };

        const setBrand = (brand) => {
            self.brand = brand;
        };

        const handleNotification = (type, message) => {
            return notification[type]({ message });
        };

        const requestData = () => {
            return self.sendRequest(REQUEST_CUSTOMER_DATA_TYPE);
        };

        const eraseData = () => {
            return self.sendRequest(ERASE_CUSTOMER_DATA_TYPE);
        };

        const previewData = () => {
            return self.sendRequest(PREVIEW_CUSTOMER_DATA_TYPE);
        };

        const validateData = (type) => {
            const isInvalidEmail = !self.email || !isEmail(self.email);
            const isKlicklySource = self.source === 'klickly';
            const isBrandSource = self.source === 'brand';

            if (!self.source) {
                throw new Error('Data source is required');
            }

            if (isBrandSource && !self.brand) {
                throw new Error('Brand name is required');
            }

            if (type === REQUEST_CUSTOMER_DATA_TYPE && isInvalidEmail) {
                throw new Error('Please, provide valid email address');
            }

            if (type === ERASE_CUSTOMER_DATA_TYPE) {
                if (isKlicklySource && isInvalidEmail) {
                    throw new Error('Please, provide valid email address');
                }

                if (isBrandSource && !self.customersToErase) {
                    throw new Error('Customer to erase is required');
                }

                if (isBrandSource && self.customersToErase === 'specific' && isInvalidEmail) {
                    throw new Error('Please, provide valid email address');
                }
            }
        };

        const sendRequest = flow(function* sendRequest(type) {
            try {
                self.validateData(type);

                yield Request.post(adminAPI.customerPrivacy.sendRequest, {
                    email: self.email || null,
                    source: self.source,
                    brandId: self.brand || null,
                    type
                });

                self.clearData();

                return self.handleNotification(
                    'success',
                    'Customer data requested successfully, and will be emailed to you shortly.'
                );
            } catch (e) {
                return self.handleNotification('error', e.message);
            }
        });

        const searchBrands = flow(function* sendRequest(search) {
            try {
                const searchParams = new URLSearchParams({ search });
                const response = yield Request.get(`${adminAPI.account.list}?${searchParams.toString()}`);
                self.brandsList = response.data;
            } catch (e) {
                return self.handleNotification('error', e.message);
            }
        });

        return {
            clearData,
            setEmail,
            setSource,
            setBrandSearch,
            setCustomersToErase,
            setBrand,
            handleNotification,
            requestData,
            eraseData,
            previewData,
            validateData,
            sendRequest,
            searchBrands
        };
    });

import { message } from 'antd';
import dayjs from 'dayjs';
import { flow, types } from 'mobx-state-tree';
import { adminAPI } from '../config';
import Request from '../utils/Request';

export const OrderHistoryOneStore = types
    .model('OrderHistoryOneStore', {
        needToReFetch: false,
        isLoading: false,
        order: types.maybeNull(types.frozen()),
        isCancelLoading: false,
        displayModal: false,
        isCancelNoteRequired: false
    })
    .views((self) => ({
        get drawerTitle() {
            if (self.order) {
                return `Order ${self.order.orderNumber}; ${dayjs(self.order.createdAt).format(
                    'MMMM DD, YYYY, hh:mm:ss'
                )}`;
            }

            return 'Loading order...';
        },
        get fulfillment() {
            let fulfillment = null;
            if (self.order && self.order.fulfillmentStatus) {
                fulfillment = self.order.fulfillments[0] || null;
            }
            return fulfillment;
        }
    }))
    .actions((self) => {
        const onCancelSelectChanged = (value) => {
            self.isCancelNoteRequired = value === 'Other';
            return true;
        };

        const toggleModal = (visible) => {
            self.displayModal = visible;
        };

        const init = flow(function* init(orderId) {
            try {
                self.order = yield Request.get(adminAPI.orders.get(orderId));
                self.isLoading = false;
            } catch (error) {
                message.error(error.message);
            }
        });

        const onCancelOrderSubmit = flow(function* onCancelOrderSubmit(values) { // eslint-disable-line
            const notes = [values.cancellationReason];
            if (values.customNotes) {
                notes.push(values.customNotes);
            }
            self.isCancelLoading = true;
            try {
                yield Request.post(adminAPI.orders.refund(self.order._id), {
                    note: notes.join(', '),
                    refundCommission: values.refundCommission
                });
                self.order.orderStatus = 'refunded';
                self.displayModal = false;
                self.needToReFetch = true;
            } catch (error) {
                message.error(error.message);
            }

            self.isCancelLoading = false;
            return true;
        });

        return {
            onCancelSelectChanged,
            toggleModal,
            init,
            onCancelOrderSubmit
        };
    });

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageIPWhitelist } from './IPWhitelist';
import { PageMFASetup } from './Setup';
import { PageMFAVerify } from './Verify';

export const PageMFA = () => {
    return (
        <Switch>
            <Route exact path='/mfa/verify' component={PageMFAVerify} />
            <Route exact path='/mfa/setup' component={PageMFASetup} />
            <Route exact path='/mfa/ip-whitelist' component={PageIPWhitelist} />
        </Switch>
    );
};
